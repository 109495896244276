import React, { useState, Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Dropdown,
  Col,
  Form,
  Row,
  Select,
  Button,
  Space,
  Menu
} from 'antd'
import {
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

import cn from '../../../utils/cn'
import { wordEndingByCount, formatDate, formatDateTime } from '../../../helpers'
import { Table, TableFooter, Tooltip } from '../../../components'
import NewDataForm from './new-data-form'

const MAX_RECORDS_IN_PAGINATOR = 40
@withRouter
@cn('verification-area-calculators-table')
export default class CalculatorsTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedFilterManipulationStatus: null
    }
    this.timeoutId = null
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
      this.updatePaginationInfo(1, null)
    }, 500)
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  onCurrentPageChange = (newPage) => {
    this.updatePaginationInfo(newPage, null)
  };

  updatePaginationInfo = (page, sortingOrder) => {
    let sorterString
    if (sortingOrder) {
      const { order, columnKey } = sortingOrder
      if (order === 'ascend') {
        sorterString = columnKey
      } else if (order === 'descend') {
        sorterString = `-${columnKey}`
      }
    }
    const { updatePagination } = this.props
    updatePagination(page, sorterString)
  };

  get layerColumns () {
    const {
      menuClickHandler = () => { },
    } = this.props
    return [
      {
        title: 'Наименование слоя',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (value, row) => {
          return (
            <span className="hoverable-link" onClick={() => this.selectLayer(row?.layer_id_ghb)}>
              {value}
            </span>
          )
        }
      },
      {
        title: 'Количество объектов',
        dataIndex: 'num_of_objects',
        key: 'num_of_objects',
        sorter: true
      },
      {
        title: 'Дата последнего обновления',
        dataIndex: 'updated',
        key: 'updated',
        sorter: true,
        render: value => value ? formatDate(value) : null
      },
      {
        title: '',
        key: 'actions',
        render: (_, row) => {
          const [isActive, setIsActive] = useState()
          return {
            children: (
              <Dropdown
                overlay={(
                  <Menu onClick={() => setIsActive(false)}>
                    <Menu.Item onClick={() => menuClickHandler('updateLayer', row)}>
                      Обновить
                    </Menu.Item>
                    <Menu.Item onClick={() => menuClickHandler('deleteLayer', row)}>
                      Удалить
                    </Menu.Item>
                  </Menu>
                )}
                trigger={['click']}
                onVisibleChange={(visible) => {
                  setIsActive(visible)
                }}>
                <MoreOutlined style={{ color: isActive ? '#03B575' : '' }} />
              </Dropdown>
            )
          }
        }
      }
    ]
  }

  get reportColumns () {
    const {
      areaTypes = () => [],
      menuClickHandler = () => { }
    } = this.props
    return [
      {
        title: 'Номер участка',
        dataIndex: 'polygon_num',
        key: 'polygon_num'
      },
      {
        title: 'Дата выезда',
        dataIndex: 'scouted_date',
        key: 'scouted_date',
        render: (val) => val ? formatDateTime(val) : '-'
      },
      {
        title: 'Кадастровый номер',
        dataIndex: 'cadastral_num',
        key: 'cadastral_num'
      },
      {
        title: 'Вид угодья установленный',
        dataIndex: 'area_type_auto',
        key: 'area_type_auto',
        render: (val) => {
          const result = areaTypes.find(item => item.value === val)
          return result ? result.name : 'Не указано'
        }
      },
      {
        title: 'Вид угодья фактический',
        dataIndex: 'area_type_fact',
        key: 'area_type_fact',
        render: (val) => {
          const result = areaTypes.find(item => item.value === val)
          return result ? result.name : 'Не указано'
        }
      },
      {
        title: 'Площадь, га',
        dataIndex: 'area',
        key: 'area'
      },
      {
        title: 'Координаты центроида участка',
        dataIndex: 'centroid',
        key: 'centroid',
        render: (val) => {
          return Array.isArray(val.coordinates) ? val.coordinates.join(' ') : '-'
        }
      },
      {
        title: 'Исполнитель',
        dataIndex: 'user',
        key: 'user',
        render: (val) => {
          return (val.first_name && val.last_name) ? `${val.first_name} ${val.last_name}` : '-'
        }
      },
      {
        title: 'Фотоотчет',
        dataIndex: 'number_of_photos',
        key: 'number_of_photos',
        render: (val) => {
          const count = Number(val)
          if (count && !Number.isNaN(count)) {
            return `${count} фото`
          } else {
            return 'Нет фото'
          }
        }
      },
      {
        title: 'Документы',
        dataIndex: 'number_of_documents',
        key: 'number_of_documents',
        render: (val) => {
          const count = Number(val)
          if (count && !Number.isNaN(count)) {
            return `${count} ${wordEndingByCount(count, 'файл', ['', 'a', 'ов'])}`
          } else {
            return 'Нет документов'
          }
        }
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment'
      },
      {
        key: 'actions',
        fixed: 'right',
        render: (_, row) => {
          const [isActive, setIsActive] = useState()
          return {
            children: (
              <Dropdown
                overlay={(
                  <Menu onClick={() => setIsActive(false)}>
                    <Menu.Item onClick={() => menuClickHandler('attachPhotos', row)}>
                      Прикрепить фотоотчет
                    </Menu.Item>
                    <Menu.Item onClick={() => menuClickHandler('attachDocuments', row)}>
                      Прикрепить документы
                    </Menu.Item>
                    {row.number_of_photos &&
                      <Menu.Item onClick={() => menuClickHandler('downloadPhotos', row)}>
                        Скачать фотоотчет на устройство
                      </Menu.Item>
                    }
                    {row.number_of_documents &&
                      <Menu.Item onClick={() => menuClickHandler('downloadDocuments', row)}>
                        Скачать документы на устройство
                      </Menu.Item>
                    }
                  </Menu>
                )}
                trigger={['click']}
                onVisibleChange={(visible) => {
                  setIsActive(visible)
                }}>
                <MoreOutlined style={{ color: isActive ? '#03B575' : '' }} />
              </Dropdown>
            )
          }
        }
      }
    ]
  }

  get filterItems () {
    const { totalSize, pageSize } = this.props
    const items = []

    for (let i = pageSize; i < totalSize && i <= MAX_RECORDS_IN_PAGINATOR; i += pageSize) {
      items.push({ value: i.toString(), title: i.toString() })
    }

    return items
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    this.updatePaginationInfo(1, sorter)
  }

  selectLayer = (newLayerId) => {
    const {
      openLayer = () => { }
    } = this.props
    if (newLayerId) {
      openLayer(newLayerId)
    }
  }

  selectFilter = (filterCategory, newValue) => {
    switch (filterCategory) {
      case 'manipulation_status':
        this.setState({ selectedFilterManipulationStatus: newValue })
        break
    }
  }

  get visibleRows () {
    const { dataRows } = this.props
    const { selectedFilterManipulationStatus } = this.state
    const items = []
    dataRows.forEach(row => {
      if (
        selectedFilterManipulationStatus &&
        row.manipulation_status !== selectedFilterManipulationStatus
      ) {
        return
      }
      items.push(row)
    })
    return items
  }

  render () {
    const { cn } = this
    const {
      totalSize,
      tableLoading,
      openModal = () => { },
      openedLayer,
      pageSize,
      areaTypes,
      currentPage,
      visibleLayers = []
    } = this.props

    const layerOptions = Array.isArray(visibleLayers) ? visibleLayers.map(x => ({
      label: x?.name,
      value: x?.layer_id_ghb
    })) : []

    return (!openedLayer && !layerOptions.length) ? (
      <NewDataForm openModal={openModal} />
    ) : (
      <div className={cn()}>
        <Form>

          <Row gutter={20}>
            <Col span={6} className="field">
              <Space direction="horizontal" style={{ marginBottom: '5px' }}>
                {!openedLayer && <Tooltip placement="topLeft" title="Подготовить объекты нового слоя к расчетам">
                  <Button onClick={() => openModal(true)} type="primary">+</Button>
                </Tooltip>}
                <div>
                  {openedLayer ? 'Слой' : 'Слои участков проверки'}
                </div>
                {!openedLayer && <Tooltip
                  title="Таблица хранит перечень слоев, доступных к использованию при работе в системе">
                  <QuestionCircleOutlined
                    style={{ color: 'rgba(0,0,0,0.45)', fontSize: '20px' }}
                  />
                </Tooltip>}
              </Space>
              <Form.Item name="layer">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    defaultValue={openedLayer}
                    placeholder="Выберите слой"
                    onChange={this.selectLayer}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                    options={layerOptions}
                  />
                  {openedLayer && <Tooltip placement="topLeft" title="Подготовить объекты нового слоя к расчетам">
                    <Button onClick={() => openModal(true)} type="primary">+</Button>
                  </Tooltip>}
                </div>
              </Form.Item>
            </Col>
            {openedLayer && (
              <>
                <Col span={6} className="field">
                  <Form.Item
                    name="presence_status"
                    label="Фактический вид угодья"
                  >
                    <Select
                      defaultValue={null}
                      placeholder="Выберите вид угодья"
                      allowClear
                      onChange={value => this.selectFilter('presence_status', value)}
                      options={areaTypes}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>

        {openedLayer && <div className='borshevik-table__header'>
          Участки проверки
        </div>}
        <Table
          onChange={this.handleTableChange}
          rowKey="id"
          dataSource={this.visibleRows}
          columns={openedLayer ? [...this.reportColumns] : [...this.layerColumns]}
          pagination={false}
          loading={tableLoading}
        />
        {(openedLayer && this.visibleRows.length > 0) && <TableFooter
          isSized={false}
          filterItems={this.filterItems}
          showSizeChanger={false}
          onChange={this.onCurrentPageChange}
          pageSize={pageSize}
          current={currentPage}
          rowDivider={pageSize.toString()}
          dataSize={totalSize}
        />}
      </div>
    )
  }
}
