import React from 'react'
import Private from '../../containers/private-route'
import Page from './component'

const Routes = [
    <Private
        exact
        key="SamplingPointsLayer"
        path="/sampling-points-layer/:id?/:layerId?"
        component={Page} />
]

export default Routes
