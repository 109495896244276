import React, { useEffect, useState } from 'react';
import {
    Layout, Form, Row, Col, Button,
    Select,
    DatePicker,
    Space
} from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import moment from 'moment';

import {
    PanelLayout, Title
} from '../../components';
import { curMeteodataForecast, curMeteodataGtk, curMeteodataPest, curMeteodataRegistry, curMeteodataSum } from '../../models/meteodata-registry/selectors';
import { getMeteodataStations, getMeteodataRegistry, getMeteodataGtk, getMeteodataSum, getMeteodataForecast, getMeteodataPest } from '../../models/meteodata-registry/actions';
import { currentUserProbability, currNamedPest, getCalculatorsMordoviaCatalog, getCalculatorsMordoviaObject, getProbabilityData } from '../../models/mordovia/selectors';
import { calculatePestProbability, calculatePestProbabilityGroup, getCalculatorsMordovia, getPestProbabilityData, getPestProbabilityDataGroup } from '../../models/mordovia/actions';
import ChartRepresentation from './components/chart';
import mockResponse from './components/example-response-charts.json';

import mockTelemetryResponse from './components/response-telemetry.json';
import mockStationsResponse from './components/response-meteostations.json';
import mockGtkResponse from './components/response-gtk.json';
import mockForecastResponse from './components/response-forecast.json';
import mockPestResponse from './components/response-pest.json';
import mockSumResponse from './components/response-effective.json';

import mockStations from './components/example-response-stations.json';
import FieldInfo from './components/field-info';
import { usrListResponse } from '../../models/sample-log/selectors';
import { getUsersList } from '../../models/sample-log/actions';
import { getAnalysisData, getEffectiveData, getMeteodata, getPrecipitationData } from '../../models/item-meteodata/actions';
import { curAnalysisMeteodata, curEffectiveMeteodata, curPrecipitationMeteodata } from '../../models/item-meteodata/selectors';
import { curMycoFields } from '../../models/mycological-registry/selectors';
import { getFilteredFields } from '../../models/mycological-registry/actions';

const { PanelHeader, PanelContent } = PanelLayout;

const { RangePicker } = DatePicker;

const FedRegistry = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const cn = useClassName('calculators');
    const { t } = useTranslation('meteodata registry');
    const match = useRouteMatch();
    const history = useHistory();

    const users = useSelector(state => usrListResponse(state));
    const stations = useSelector(state => getMeteodataStations(state));
    const meteodata = useSelector(state => curMeteodataRegistry(state));
    const gtk = useSelector(state => curMeteodataGtk(state));
    const pest = useSelector(state => curMeteodataPest(state));
    const forecast = useSelector(state => curMeteodataForecast(state));
    const sum = useSelector(state => curMeteodataSum(state));
    //
    const fields = useSelector(state => getCalculatorsMordoviaObject(state));

    useEffect(() => {
        console.log('Checking non-mock data', meteodata, gtk, pest, forecast, sum)
    }, [meteodata, gtk, pest, forecast, sum])
    
    
    useEffect(() => {
        console.log('MOCK DATA CHECKER, [mockTelemetryResponse]', mockTelemetryResponse)
        console.log('MOCK DATA CHECKER, [mockStationsResponse]', mockStationsResponse)
        console.log('MOCK DATA CHECKER, [mockGtkResponse]', mockGtkResponse)
        console.log('MOCK DATA CHECKER, [mockForecastResponse]', mockForecastResponse)
        console.log('MOCK DATA CHECKER, [mockPestResponse]', mockPestResponse)
        console.log('MOCK DATA CHECKER, [mockSumResponse]', mockSumResponse)
    }, [
        mockTelemetryResponse,
        mockStationsResponse,
        mockGtkResponse,
        mockForecastResponse,
        mockPestResponse,
        mockSumResponse
    ])
    

    useEffect(() => {
        dispatch(getCalculatorsMordovia(`?page=${fieldsPage}&page_size=100`));
    }, [fieldsPage]);

    useEffect(() => {
        console.log('mockResponse', mockResponse);
    }, [mockResponse]);

    useEffect(() => {
        console.log('mockStations', mockStations);
    }, [mockStations]);
    
    useEffect(() => {
        console.log('FIELDS!!!', fields);
    }, [fields]);

    useEffect(() => {
        console.log('STATIONS', stations);
    }, [stations]);

    useEffect(() => {
        console.log('meteodata', meteodata);
    }, [meteodata]);

    const [fieldEntity, setFieldEntity] = useState([]);
    const [chartGtk, setChartGtk] = useState([]);
    const [chartTelemetry, setChartTelemetry] = useState([]);
    const [currentGtk, setCurrentGtk] = useState([]);
    // const [culture, setCulture] = useState(null);
    const [fieldName, setFieldName] = useState([]);
    const [meteostations, setMeteostations] = useState([]);
    const [dates, setDates] = useState([]);
    const [fullUsersList, setFullUsersList] = useState([]);
    const [usersPage, setUsersPage] = useState(1);
    const [dataWasRequested, setDataWasRequested] = useState(false);

    useEffect(() => {
        dispatch(getUsersList({
            page: usersPage,
            searchString: ''
        }));
    }, [usersPage]);

    useEffect(() => {
        console.log('users', users);
        const listPortion = users?.results;
        if (listPortion?.length > 0) {
            setFullUsersList([...fullUsersList, ...listPortion]);
        }
        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUsersPage(usersPage + 1);
        }
    }, [users]);

    const analysisData = useSelector(state => curAnalysisMeteodata(state));
    const effectiveData = useSelector(state => curEffectiveMeteodata(state));
    const precipitationData = useSelector(state => curPrecipitationMeteodata(state));

    useEffect(() => {
        console.log('analysisData', analysisData);
    }, [analysisData]);

    useEffect(() => {
        console.log('effectiveData', effectiveData);
    }, [effectiveData]);

    useEffect(() => {
        console.log('precipitationData', precipitationData);
    }, [precipitationData]);
    
    useEffect(() => {
        if (owner) {
            // dispatch()
        }
    }, [owner])

    const fieldsNew = useSelector(state => curMycoFields(state));
    const [fieldList, setFieldList] = useState([]);
    const [fieldsPage, setFieldsPage] = useState(1);

    // useEffect(() => {
    //     // const { owner } = form?.getFieldsValue();
    //     // owner && dispatch(getFilteredFields({
    //     //     userId: owner,
    //     //     pageNum: fieldsPage
    //     // }));
    // }, [fieldsPage]);

    useEffect(() => {
        const fieldsListArr = fields?.results;
        console.log('fieldsListArr', fieldsListArr);
        const fieldsCpy = [...fieldList];
        fieldsListArr.forEach(item => {
            console.log('item', item);
            const foundField = fieldsCpy?.filter(x => x?.value === item?.id)[0];
            console.log('foundField', foundField);
            if (!foundField?.label?.length > 0) {
                fieldsCpy.push({
                    label: `${item?.vega_key}`,
                    value: item?.id
                });
            }
        });

        setFieldList([...fieldsCpy]);
        if (fields?.next?.length > 0) {
            setFieldsPage(fieldsPage + 1);
        }
    }, [fields]);
    

    useEffect(() => {
        if (fieldName?.length > 0 && dates?.length === 2) {
            // console.log('CHECKING DATES', moment.utc(dates[0]).format(), moment.utc(dates[1]).format())
            dispatch(getMeteodataStations({
                start: moment.utc(dates[0]).format(),
                end: moment.utc(dates[1]).format(),
                fields: fieldName
            }));

            // if (fieldYear?.length > 0) {
                const currField = fieldName?.[0];
                dispatch(getMeteodata({
                    start_date: dates?.[0]?.format("YYYY-MM-DD"),
                    end_date: dates?.[1]?.format("YYYY-MM-DD"),
                    fields: fieldName
                    // vega_key: currField?.vega_key,
                    // body: {
                    //     start_date: dates?.[0]?.format("YYYY-MM-DD"),
                    //     end_date: dates?.[1]?.format("YYYY-MM-DD"),
                    //     fields: fieldName
                    //     // year: fieldYear || 2024
                    // }
                }));
                dispatch(getAnalysisData({
                    start_date: dates?.[0]?.format("YYYY-MM-DD"),
                    end_date: dates?.[1]?.format("YYYY-MM-DD"),
                    fields: fieldName
                    // vega_key: currField?.vega_key,
                    // body: {
                    //     start_date: dates?.[0]?.format("YYYY-MM-DD"),
                    //     end_date: dates?.[1]?.format("YYYY-MM-DD"),
                    //     fields: fieldName
                    //     // year: fieldYear || 2024
                    // }
                }));
                dispatch(getEffectiveData({
                    start_date: dates?.[0]?.format("YYYY-MM-DD"),
                    end_date: dates?.[1]?.format("YYYY-MM-DD"),
                    fields: fieldName
                    // vega_key: currField?.vega_key,
                    // body: {
                    //     start_date: dates?.[0]?.format("YYYY-MM-DD"),
                    //     end_date: dates?.[1]?.format("YYYY-MM-DD"),
                    //     fields: fieldName
                    //     // year: fieldYear || 2024
                    // }
                }));
                dispatch(getPrecipitationData({
                    start_date: dates?.[0]?.format("YYYY-MM-DD"),
                    end_date: dates?.[1]?.format("YYYY-MM-DD"),
                    fields: fieldName
                    // vega_key: currField?.vega_key,
                    // body: {
                    //     start_date: dates?.[0]?.format("YYYY-MM-DD"),
                    //     end_date: dates?.[1]?.format("YYYY-MM-DD"),
                    //     fields: fieldName
                    //     // year: fieldYear || 2024
                    // }
                }));
            // }
        }
    }, [fieldName, dates, owner, fieldYear]);

    useEffect(() => {
        console.log('fname', fieldName);
        if (fieldName?.length) {
            setFieldEntity(fieldName?.map(fieldId => {
                return fieldList?.filter(x => x?.id === fieldId)?.[0];
            }))
        } else {
            setFieldEntity([fieldName].map(fieldId => {
                return fieldList?.filter(x => x?.id === fieldId)?.[0];
            }))
        }
        // setFieldEntity(fields?.filter(x => x?.id === fieldName)[0]);
    }, [fieldName])
    
    

    const handleClearValues = () => {
        setDataWasRequested(false);
        setDates([]);
        setMeteostations([]);
        setFieldName([]);
        // setCulture(null);
    };

    const checkAllProbs = useSelector(state => currNamedPest(state));

    useEffect(() => {
        console.log('checkAllProbs', checkAllProbs);
    }, [checkAllProbs]);
    

    const handleGetData = () => {
        setDataWasRequested(true)

        const fieldCultures = [];
        const fieldSowings = [];
        fieldEntity?.forEach(fEn => {
            const gotIt = fEn?.year_group?.years?.filter(x => x?.year === fieldYear)?.[0];
            fieldCultures?.push(gotIt?.culture);
            fieldSowings?.push(gotIt?.sowing_date);
        })

        const fil = fieldEntity?.map(x => {
            return { id: x?.vega_key, body: {} };
        });
        
        fieldCultures.forEach((clt, idx) => {
            fil[idx].body.culture = clt?.id;
            fil[idx].body.sowing_date = fieldSowings[idx];
            fil[idx].body.date = dates.map(x => x.format("YYYY-MM-DD"))[0]
        });

        dispatch(getMeteodataRegistry({
            start: moment.utc(dates[0]).format(),
            end: moment.utc(dates[1]).format(),
            fields: fieldName
        }));
        dispatch(getMeteodataGtk({
            start: moment.utc(dates[0]).format(),
            end: moment.utc(dates[1]).format(),
            fields: fieldName
        }));
        dispatch(getMeteodataSum({
            start: moment.utc(dates[0]).format(),
            end: moment.utc(dates[1]).format(),
            fields: fieldName
        }));
        dispatch(getMeteodataForecast({
            start: moment.utc(dates[0]).format(),
            end: moment.utc(dates[1]).format(),
            fields: fieldName
        }));
        dispatch(getMeteodataPest({
            start: moment.utc(dates[0]).format(),
            end: moment.utc(dates[1]).format(),
            fields: fieldName
        }));
    };

    const usrProb = useSelector(state => currentUserProbability(state));
    const currentPests = useSelector(state => getProbabilityData(state));

    const userProbs = [];
    useEffect(() => {
        console.log('currentPests', currentPests);
        console.log('usrProb', usrProb);
        if (usrProb?.pests) {
            userProbs.push(usrProb?.pests);
        }
    }, [currentPests, usrProb]);
    

    const [chartLegend, setChartLegend] = useState([]);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        console.log('mockResponse', mockResponse);
        // const { telemetry, legend, gtk } = mockResponse;
        const { telemetry, legend, gtk } = mockTelemetryResponse;
        setChartGtk(gtk);
        setChartTelemetry(telemetry);
        let selectedData;
        let selectedGtk;
        // if (meteostation) {
        //     selectedGtk = gtk.filter(x => x.id === meteostation)[0];
        //     selectedData = telemetry.filter(x => x.id === meteostation)[0];
        // } else {
        //     selectedGtk = gtk[0];
        //     selectedData = telemetry[0];
        // }
        selectedGtk = gtk?.[0];
        selectedData = telemetry[0];
        setChartLegend(legend);
        setChartData(selectedData.data);
        setCurrentGtk(selectedGtk);
    }, [mockResponse]);
    
    const getFormattedDate = (momentValue) => {
        return `${t(moment(momentValue).format('dddd'))}, ${t(moment(momentValue).format('d'))} ${t(moment(momentValue).format('MMMM'))}`;
    };

    const [owner, setOwner] = useState();
    const [fieldYear, setFieldYear] = useState([]);

    useEffect(() => {
        console.log('fieldEntity', fieldEntity);
    }, [fieldEntity]);

    const getFieldYears = () => {
        const fieldYears = [];
        fieldEntity?.forEach(x => {
            x?.years?.map(y => {
                if (!fieldYears?.includes(y)) {
                    fieldYears.push(y);
                }
            })
        })
        return fieldYears?.map(x => {
            return {
                label: x,
                value: x
            }
        })
    }

    const [fieldPeriod, setFieldPeriod] = useState(null);
    const fieldPeriodOptions = [
        {
            label: t('veg'),
            value: 1
        },
        {
            label: t('still'),
            value: 2
        }
    ];

    const getFieldNames = () => {
        // if (owner) {
        //     return fieldList?.filter(x => owner === x?.owner_user?.id)?.map(x => {
        //         return {
        //             label: x?.vega_key,
        //             value: x?.id
        //         };
        //     })
        // }

        return fieldList;
    }

    return (
        <Layout key="page-fed-registry">
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={`${t('title')}`} />
                </PanelHeader>
                <PanelContent>
                    <Space style={{ width: '100%' }} direction='vertical'>
                        <Row style={{ width: '100%' }} gutter={20}>
                            <Col span={10}>
                                <Row>
                                    <Col span={8}>{t('zakazchik')}</Col>
                                    <Col span={16}><Select disabled={dataWasRequested} options={fullUsersList?.map(x => {
                                        return {
                                            label: x?.name,
                                            value: x?.id
                                        };
                                    })} onSelect={setOwner} /></Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row>
                                    <Col span={8}>{t('field period')}</Col>
                                    <Col span={16}><Select disabled={dataWasRequested} options={fieldPeriodOptions} onChange={setFieldPeriod} style={{ width: '100%' }} /></Col>
                                </Row>
                            </Col>
                            {/* <Col span={3}>
                                {dates?.length === 2 ? `${getFormattedDate(dates[0])} - ${getFormattedDate(dates[1])}` : ""}
                            </Col> */}
                        </Row>
                        <Row style={{ width: '100%' }} gutter={20}>
                            <Col span={10}>
                                <Row>
                                    <Col span={8}>{t('god polya')}</Col>
                                    <Col span={16}><Select disabled={dataWasRequested} options={fieldEntity?.length > 0 ? getFieldYears() : []} onSelect={setFieldYear} /></Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row>
                                    <Col span={8}>{t('inspection time')}</Col>
                                    <Col span={16}><RangePicker disabled={dataWasRequested} onChange={setDates} style={{ width: '100%' }} /></Col>
                                </Row>
                            </Col>
                            <Col span={3}>
                                {dates?.length === 2 ? `${getFormattedDate(dates[0])} - ${getFormattedDate(dates[1])}` : ""}
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }} gutter={20}>
                            <Col span={10}>
                                <Row>
                                    <Col span={8}>{t('field name')}</Col>
                                    <Col span={16}><Select disabled={dataWasRequested} maxTagCount={1} mode='multiple' options={getFieldNames()} onChange={setFieldName} /></Col>
                                    {/* <Col span={16}><Select maxTagCount={1} mode='multiple' options={[{ label: 'shitfield', id: '228' },  { label: 'shit3field', id: '2238' }]} onChange={setFieldName} /></Col> */}
                                    {/* <Col span={8}>{t('culture')}</Col>
                                    <Col span={16}><Select onSelect={setCulture} /></Col> */}
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row>
                                    <Col span={8}>{t('meteostation')}</Col>
                                    <Col span={16}><Select disabled={dataWasRequested} mode='multiple' options={mockStations?.[0]?.meteoStations.map(x => ({
                                        label: x.name,
                                        value: x.id
                                    }))} onChange={setMeteostations} /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }} gutter={20}>
                            <Col span={24}>
                                <Button type='primary' disabled={dataWasRequested} onClick={handleGetData}>{t('get data')}</Button>
                                {dataWasRequested && (<Button style={{ marginLeft: '15px' }} onClick={() => setDataWasRequested(false)}>{t('edit')}</Button>)}
                                <Button style={{ marginLeft: '15px' }} onClick={handleClearValues}>{t('clear')}</Button>
                            </Col>
                        </Row>
                        <hr />
                        <Row gutter={20} style={{ marginTop: '45px' }}>
                            <Col span={16}>
                                <Row>
                                    {/* <Col span={16}> */}
                                    <ChartRepresentation selectedFields={fieldEntity} currFieldName={fieldEntity?.[0]?.vega_key} chartName={t('chart air')} chartLegend={chartLegend} chartType="air" chartData={chartData} style={{ width: '100%' }} />
                                    {/* </Col> */}
                                </Row>
                                <Row style={{ marginTop: '45px' }}>
                                    {/* <Col span={16}> */}
                                    <ChartRepresentation selectedFields={fieldEntity} currFieldName={fieldEntity?.[0]?.vega_key} chartName={t('chart soil')} chartLegend={chartLegend} chartType="soil" chartData={chartData} style={{ width: '100%' }} />
                                    {/* </Col> */}
                                </Row>
                                <Row style={{ marginTop: '45px' }}>
                                    {/* <Col span={16}> */}
                                    <ChartRepresentation selectedFields={fieldEntity} currFieldName={fieldEntity?.[0]?.vega_key} chartName={t('chart precipitation')} chartLegend={chartLegend} chartType="precipitation" chartData={chartData} style={{ width: '100%' }} />
                                    {/* </Col> */}
                                </Row>
                                <Row style={{ marginTop: '45px' }}>
                                    {/* <Col span={16}> */}
                                    <ChartRepresentation selectedFields={fieldEntity} currFieldName={fieldEntity?.[0]?.vega_key} chartName={t('chart wind')} chartLegend={chartLegend} chartType="wind" chartData={chartData} style={{ width: '100%' }} />
                                    {/* </Col> */}
                                </Row>
                                <Row style={{ marginTop: '45px' }}>
                                    {/* <Col span={16}> */}
                                    {/* <ChartRepresentation selectedFields={fieldEntity} currFieldName={fieldEntity?.[0]?.vega_key} chartName={t('chart harmful')} chartLegend={chartLegend} chartType="harmful" chartData={chartData} style={{ width: '100%' }} /> */}
                                    {/* </Col> */}
                                </Row>
                                <Row style={{ marginTop: '45px' }}>
                                    {/* <Col span={16}> */}
                                    <ChartRepresentation selectedFields={fieldEntity} currFieldName={fieldEntity?.[0]?.vega_key} userProbs={userProbs} chartName={t('chart gtk')} chartLegend={chartLegend} chartType="gtk" chartData={currentGtk} style={{ width: '100%' }} />
                                    {/* </Col> */}
                                </Row>
                            </Col>
                            <Col span={8}>
                                {fieldEntity?.length > 0 && (
                                    <FieldInfo selectedSources={meteostations} calculator={fieldEntity} />
                                )}
                            </Col>
                        </Row>
                    </Space>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default FedRegistry;