import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
    Col, Form, InputNumber, Row, Select
} from 'antd'
import { useClassName } from 'utils/cn'

import { actions, selectors } from '../../../../models'

import '../../../../containers/item-normative/style.less'
import './style.less'
import { Button } from '../../../../components'
import { setCurDistributionCalculate } from '../../../../reducers/view/actions'
import FeedingModal from './feeding-modal'


const {
    getRegionNormCultureCatalog,
    isAuth,
    isCalculateCompleted,
    curInterpretation
} = selectors

const {
    getCalculatorsNormRegionCulture,
    addNormative,
    updateNormative,
    addNutrition,
    updateNutrition
} = actions

export default ({
    calculator, normative, nutrition, distribution
}) => {
    const isAuthState = useSelector(state => isAuth(state))
    const interpretation = useSelector(state => curInterpretation(state))
    const cultures = useSelector(state => getRegionNormCultureCatalog(state))
    const isCompleted = useSelector(state => isCalculateCompleted(state))
    const dispatch = useDispatch()
    const { t } = useTranslation('calculators')
    const cn = useClassName('calculator')
    const match = useRouteMatch()
    const { params: { id } } = match
    const [form] = Form.useForm()
    const [isFull, setIsFull] = useState(true)
    const [visibleFeedingModal, setVisibleFeedingModal] = useState(false)

    useEffect(() => {
        normative?.region?.id
            && dispatch(getCalculatorsNormRegionCulture(`?filter={"region.id":${normative?.region?.id}}`))
        form.setFieldsValue({
            planned_yield: normative?.planned_yield,
            culture: isAuthState ? normative?.culture?.id : normative?.culture?.name
        })
    }, [calculator, isAuthState])

    const sendData = (type) => {
        if (nutrition?.id ?? type === 'nutrition') {
            dispatch(setCurDistributionCalculate({ type, calculator: calculator?.id }))
        } else if (type === 'normative') {
            dispatch(setCurDistributionCalculate({ type, calculator: calculator?.id }))
        } else {
            dispatch(setCurDistributionCalculate({ type, calculator: calculator?.id }))
        }
    }

    const onFieldsChange = (field, fields) => {
        let isFull = true

        fields.forEach(field => {
            if (!field.value) isFull = false
        })
        setIsFull(isFull)
    }

    const onValuesChange = (value, values) => {
        if (Object.keys(value)[0] === 'region') {
            const filter = `?filter={"region.id":${value.region}}`
            dispatch(getCalculatorsNormRegionCulture(filter))
            form.setFieldsValue({ culture: '' })
        }
    }

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase()
        inputName = inputName.toLowerCase()
        return optionName.indexOf(inputName) !== -1
    }

    const reCalcNormativeNutrition = () => {
        const values = form.getFieldsValue(true)
        const updateValuesNorm = {
            planned_yield: values?.planned_yield || normative?.planned_yield,
            region: normative?.region?.id,
            culture: values?.culture || normative?.culture?.id,
            preceding_culture: normative?.preceding_culture?.id,
            ph_salt: Number(normative?.ph_salt),
            mechanical_composition: normative?.mechanical_composition?.id
        }
        const updateValuesNutrition = {
            planned_yield: values?.planned_yield || normative?.planned_yield,
            region: normative?.region?.id,
            culture: values?.culture || normative?.culture?.id,
            preceding_culture: normative?.preceding_culture?.id,
            ph_salt: nutrition?.ph_salt,
            mechanical_composition: normative?.mechanical_composition?.id,
            no3: Number(nutrition?.no3),
            k2o: Number(nutrition?.k2o),
            p2o5: Number(nutrition?.p2o5),
            method: nutrition?.method
        }
        nutrition?.id
            ? dispatch(updateNutrition({ id: nutrition?.id, values: updateValuesNutrition }))
            : dispatch(addNutrition({
                calculate: {
                    id,
                    name: calculator?.name,
                    calculator_type: calculator?.calculator_type
                },
                values
            }))

        normative?.id
            ? dispatch(updateNormative({ id: normative?.id, values: updateValuesNorm }))
            : dispatch(addNormative({
                calculate: {
                    id,
                    name: calculator?.name,
                    calculator_type: calculator?.calculator_type
                },
                values
            }))
    }

    return (
        <div>
            <Form
                onFieldsChange={onFieldsChange}
                onValuesChange={onValuesChange}
                form={form}
                className={cn()}
                name="calculator-params">
                <div className={cn('params')}>
                    <Row gutter={16}>
                        <Col span={7}>{t('planned yield t')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="planned_yield"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber
                                    min={0}
                                    max={1000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('culture')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="culture"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled="{true}"
                                    filterOption={filterOption}>
                                    {
                                        cultures.map(
                                            (item => (
                                                <Select.Option
                                                    value={item.culture?.id}
                                                    key={item.culture?.id}>
                                                    {item.culture?.name}
                                                </Select.Option>
                                            ))
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    {!isFull && (
                        <Row className={cn('info')}>
                            <Col>
                                <div className={cn('info__inner')}>{t('info')}</div>
                            </Col>
                        </Row>
                    )}
                    {
                        normative?.id && normative?.expert_data && (
                            <>
                                <div className="table-percent">
                                    <div className="table-percent__title">{t('table title expert')}</div>
                                    <Row className="table-percent__header">
                                        <Col span={4}>{t('table name')}</Col>
                                        <Col span={5}>{t('table autumn')}</Col>
                                        <Col span={5}>{t('table spring')}</Col>
                                        <Col span={5}>{t('table feeding')}</Col>
                                        <Col span={5}>{t('table total')}</Col>
                                    </Row>
                                    <Row className="table-percent__row">
                                        <Col span={4}>{t('table n')}</Col>
                                        <Col span={5}>{normative?.expert_n_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.expert_n_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.expert_n_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{(+normative?.expert_n_main + normative?.expert_n_sowing + normative?.expert_n_feeding)?.toLocaleString('ru-RU')}</Col>
                                    </Row>
                                    <Row className="table-percent__row">
                                        <Col span={4}>{t('table p')}</Col>
                                        <Col span={5}>{normative?.expert_p_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.expert_p_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.expert_p_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{(+normative?.expert_p_main + normative?.expert_p_sowing + normative?.expert_p_feeding)?.toLocaleString('ru-RU')}</Col>
                                    </Row>
                                    <Row className="table-percent__row">
                                        <Col span={4}>{t('table k')}</Col>
                                        <Col span={5}>{normative?.expert_k_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.expert_k_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.expert_k_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{(+normative?.expert_k_main + normative?.expert_k_sowing + normative?.expert_k_feeding)?.toLocaleString('ru-RU')}</Col>
                                    </Row>
                                </div>
                                {normative?.expert_comment && (
                                    <Row className={cn('info-lg')} style={{ marginBottom: '30px' }}>
                                        <Col span={24}>
                                            <div className={cn('info__inner')}>{normative?.expert_comment}</div>
                                        </Col>
                                    </Row>
                                )}
                                {!isCompleted && (
                                    <Row className={cn('button')}>
                                        <Col>
                                            <Button
                                                type="primary"
                                                disabled={isCompleted}
                                                onClick={() => sendData('expert')}
                                                size="large">
                                                {t('calculate expert')}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )
                    }
                    <Row justify="end">
                        <div onClick={reCalcNormativeNutrition} className="link">Пересчитать</div>
                    </Row>
                    {
                        normative?.id && !normative?.expert_data && (
                            <>
                                <div className="table-percent">
                                    <Row justify="space-between">
                                        <div className="table-percent__title">{t('table title normative')}</div>
                                    </Row>
                                    <Row className="table-percent__header">
                                        <Col span={4}>{t('table name')}</Col>
                                        <Col span={5}>{t('table autumn')}</Col>
                                        <Col span={5}>{t('table spring')}</Col>
                                        <Col span={5}>{t('table feeding')}</Col>
                                        <Col span={5}>{t('table total')}</Col>
                                    </Row>
                                    <Row className="table-percent__row">
                                        <Col span={4}>{t('table n')}</Col>
                                        <Col span={5}>{normative?.recommended_n_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.recommended_n_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.recommended_n_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{(+normative?.recommended_n_main + normative?.recommended_n_sowing + normative?.recommended_n_feeding)?.toLocaleString('ru-RU')}</Col>
                                    </Row>
                                    <Row className="table-percent__row">
                                        <Col span={4}>{t('table p')}</Col>
                                        <Col span={5}>{normative?.recommended_p_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.recommended_p_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.recommended_p_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{(+normative?.recommended_p_main + normative?.recommended_p_sowing + normative?.recommended_p_feeding)?.toLocaleString('ru-RU')}</Col>
                                    </Row>
                                    <Row className="table-percent__row">
                                        <Col span={4}>{t('table k')}</Col>
                                        <Col span={5}>{normative?.recommended_k_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.recommended_k_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{normative?.recommended_k_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                        <Col span={5}>{(+normative?.recommended_k_main + normative?.recommended_k_sowing + normative?.recommended_k_feeding)?.toLocaleString('ru-RU')}</Col>
                                    </Row>
                                </div>
                                {nutrition?.id && (
                                    <>
                                        <div className="table-percent">
                                            <Row justify="space-between">
                                                <div className="table-percent__title">{t('table title nutrition')}</div>
                                            </Row>
                                            <Row className="table-percent__header">
                                                <Col span={4}>{t('table name')}</Col>
                                                <Col span={5}>{t('table autumn')}</Col>
                                                <Col span={5}>{t('table spring')}</Col>
                                                <Col span={5}>{t('table feeding')}</Col>
                                                <Col span={5}>{t('table total')}</Col>
                                            </Row>
                                            <Row className="table-percent__row">
                                                <Col span={4}>{t('table n')}</Col>
                                                <Col span={5}>{nutrition?.recommended_n_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{nutrition?.recommended_n_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{nutrition?.recommended_n_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{(+nutrition?.recommended_n_main + nutrition?.recommended_n_sowing + nutrition?.recommended_n_feeding)?.toLocaleString('ru-RU')}</Col>
                                            </Row>
                                            <Row className="table-percent__row">
                                                <Col span={4}>{t('table p')}</Col>
                                                <Col span={5}>{nutrition?.recommended_p_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{nutrition?.recommended_p_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{nutrition?.recommended_p_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{(+nutrition?.recommended_p_main + nutrition?.recommended_p_sowing + nutrition?.recommended_p_feeding)?.toLocaleString('ru-RU')}</Col>
                                            </Row>
                                            <Row className="table-percent__row">
                                                <Col span={4}>{t('table k')}</Col>
                                                <Col span={5}>{nutrition?.recommended_k_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{nutrition?.recommended_k_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{nutrition?.recommended_k_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                                <Col span={5}>{(+nutrition?.recommended_k_main + nutrition?.recommended_k_sowing + nutrition?.recommended_k_feeding)?.toLocaleString('ru-RU')}</Col>
                                            </Row>
                                        </div>
                                        <>
                                            <Row className={cn('info-lg')} style={{ marginBottom: '30px' }}>
                                                <Col span={24}>
                                                    <div className={cn('info__inner')}>{t('info for nutrition')}</div>
                                                </Col>
                                            </Row>
                                        </>
                                    </>
                                )}
                                {!isCompleted && (
                                    <div className={cn('button-field-nutrition')}>
                                        <Button
                                            type="primary"
                                            disabled={isCompleted}
                                            // onClick={() => sendData(calculatorMode)}
                                            onClick={() => setVisibleFeedingModal(true)}
                                            size="large">
                                            {t('calculate fertilizers')}
                                        </Button>
                                    </div>
                                )}
                            </>
                        )
                    }
                </div>
            </Form>
            <FeedingModal
                isModalVisible={visibleFeedingModal}
                closeModal={() => setVisibleFeedingModal(false)} />
        </div>
    )
}
