import { combineReducers } from 'redux';

import meteodata from './meteodata';
import stations from './stations';
import sum from './sum';
import gtk from './gtk';
import forecast from './forecast';
import pest from './pest';

export default combineReducers({
    meteodata,
    stations,
    sum,
    gtk,
    forecast,
    pest
});
