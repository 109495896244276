import {
    all, call, put, takeLatest
} from 'redux-saga/effects'
import { defaultSaga, getActions } from '../utils'

import * as constants from './constants'
import * as api from './api'
import converter from './converter'
import { updateCalculate } from '../item-calculate/actions'

function* addNormativeSaga ({ payload }) {
    const [start, success, failure] = getActions(
        constants.addNormative.try,
        constants.addNormative.success,
        constants.addNormative.error
    )
    try {
        const { calculate, values } = payload
        yield put(start())
        const dataRaw = yield call(api.addNormative, values)
        const data = converter.toClient(dataRaw)
        yield put(updateCalculate({ id: calculate.id, values: { normative: data.id, ...calculate } }))
        yield put(success(data))
    } catch (error) {
        yield put(failure(error))
    }
}

export default function* rootSaga () {
    yield all([
        yield takeLatest(
            constants.getNormative.toString(),
            defaultSaga({
                constants: constants.getNormative,
                api: api.getNormative,
                after: converter.toClient
            })
        ),
        yield takeLatest(
            constants.getNormatives.toString(),
            defaultSaga({
                constants: constants.getNormatives,
                api: api.getNormatives
            })
        ),
        yield takeLatest(
            constants.addNormative.toString(),
            addNormativeSaga
        ),
        yield takeLatest(
            constants.updateNormative.toString(),
            defaultSaga({
                constants: constants.updateNormative,
                api: api.updateNormative,
                after: converter.toClient
            })
        )
    ])
}
