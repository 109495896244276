import React, { Component, useState, useEffect } from 'react';

import { Collapse, Spin, Table } from 'antd';
import '../style.less';
import { plantHerbicide, mainDescription, vegetationPhases, methods } from '../table-data';
import { useClassName } from '../../../utils/cn';

const { Panel } = Collapse;

export default ({
}) => {
    const cn = useClassName('calculator-detail');

    const [activeTabs, setActiveTabs] = useState([]);

    const tableColumns = [
        {
            title: 'Название гербицида',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Содержание д.в.',
            dataIndex: 'activeSubstance',
            key: 'activeSubstance'
        },
        {
            title: 'Норма применения, кг/га',
            dataIndex: 'applicationNorm',
            key: 'applicationNorm'
        },
        {
            title: 'Опрыскивание при высоте растений  и фазе развития',
            dataIndex: 'condition',
            key: 'condition'
        },
        {
            title: 'Расход рабочей жидкости, л/га',
            dataIndex: 'consumption',
            key: 'consumption'
        }
    ]

    return (
        <div style={{ width: '100%' }}>

            <div className="header-1">Общие характеристики</div>
            <div style={{ marginTop: '24px' }}>{mainDescription[1]}</div>
            <div>{mainDescription[2]}</div>
            <div style={{ marginTop: '20px' }}><b>{mainDescription[3]}</b>{mainDescription[4]}</div>
            <div style={{ marginTop: '20px' }}><b>{mainDescription[5]}</b>{mainDescription[6]}</div>
            <div style={{ marginTop: '20px' }}><b>{mainDescription[7]}</b>{mainDescription[8]}</div>
            <div style={{ marginTop: '20px' }}><b>{mainDescription[9]}</b>{mainDescription[10]}</div>
            <div style={{ marginTop: '20px' }}>{mainDescription[11]}<b>{mainDescription[12]}</b>{mainDescription[13]}</div>

            <div className="header-1">Фазы вегетации</div>
            <div className="card-row" style={{ marginTop: '24px' }}>
                {vegetationPhases.map(vegPhas => {
                    return (
                        <div className="veg-card">
                            <div className="veg-upper">
                                <img src={vegPhas.image} className="veg-img" />
                                <div className={`veg-info`}>
                                    {vegPhas.info}
                                </div>
                            </div>
                            
                            <div className="veg-subtext">
                                <span className="veg-name">{vegPhas.name}</span>
                                <span className="veg-year">({vegPhas.year})</span>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="header-1">Методы борьбы</div>

            <Collapse
                style={{ marginTop: '24px' }}
                activeKey={activeTabs}
                onChange={setActiveTabs}
                // expandIconPosition="right"
                className={cn('collapse')}>
                    <Panel header="Механические методы"
                        key="mech-meth">
                        <div style={{ marginTop: '16px' }}>
                            <b>{methods.mech[1]}</b>{methods.mech[2]}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <b>{methods.mech[3]}</b>{methods.mech[4]}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <b>{methods.mech[5]}</b>{methods.mech[6]}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <b>{methods.mech[7]}</b>{methods.mech[8]}
                        </div>
                    </Panel>
                    <Panel header="Агротехнические методы"
                        key="agro-meth">
                        <div style={{ marginTop: '16px' }}>
                            <b>{methods.agro[1]}</b>{methods.agro[2]}
                        </div>
                        <div className="warning-box" style={{ marginTop: '16px' }}>
                            {methods.agro[3]}
                        </div>
                        <div style={{ marginTop: '24px' }}>
                            <b>{methods.agro[4]}</b>{methods.agro[5]}
                        </div>
                    </Panel>
                    <Panel header="Химический метод"
                        key="chem-meth">
                        <div style={{ marginTop: '16px' }}>
                            <b>{methods.chem[1]}</b>{methods.chem[2]}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            {methods.chem[3]}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            {methods.chem[4]}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <b>{methods.chem[5]}</b>
                        </div>
                        <Table style={{ marginTop: '16px' }} columns={tableColumns} dataSource={plantHerbicide} rowKey="name" pagination={{ pageSize: 100, hideOnSinglePage: true }} />
                    </Panel>
            </Collapse>
        </div>
    );
};