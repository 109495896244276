import * as constants from './constants';
import request from '../../utils/request';

export const getMeteodataRegistry = payload => ({
    type: constants.getMeteodataRegistry.toString(),
    payload
});

export const getMeteodataStations = payload => ({
    type: constants.getMeteodataStations.toString(),
    payload
});

export const getMeteodataGtk = payload => ({
    type: constants.getMeteodataGtk.toString(),
    payload
});

export const getMeteodataSum = payload => ({
    type: constants.getMeteodataSum.toString(),
    payload
});

export const getMeteodataForecast = payload => ({
    type: constants.getMeteodataForecast.toString(),
    payload
});

export const getMeteodataPest = payload => ({
    type: constants.getMeteodataPest.toString(),
    payload
});
