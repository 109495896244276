import request from '../../utils/request'
import options from './options'

const urlPath = (url, path) => `${url}${path}`

export const getCalculatorsMordovia = filter => request.get({
    url: urlPath(options.apiMordoviaUrl, filter)
})

export const getCalculatorMordovia = ({ id }) => request.get({
    url: urlPath(options.apiMordoviaUrl, `${id}/`)
})

export const getCalculatorsMordoviaShape = ({ objectId }) => request.get({
    url: urlPath(options.apiMordoviaShapeUrl, `?filter={"registry_object": ${objectId}}`)
})

export const getCalculatorsMordoviaForecast = ({ id, year }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${id}/forecast/`),
    body: { year }
})

export const updateCalculatorsMordoviaForecast = ({ id, body }) => request.patch({
    url: urlPath(options.apiMordoviaUrl, `${id}/forecast/`),
    body
})

export const calculatePestProbability = ({ id, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${id}/pest_probability/`),
    body
})

export const getPestProbabilityData = ({ id, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${id}/field_forecast/`),
    body
})

export const getPestProbabilityDataGroup = ({ id, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${id}/field_forecast/`),
    body
})

export const addGroupCalculate = ({ vega_key, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${vega_key}/calculator/`),
    body
})

export const updateFieldDistribution = ({ vega_key, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${vega_key}/update_distribution/`),
    body
})

export const getFieldPoints = ({ id, filter }) => request.get({
    url: urlPath(options.apiMordoviaPointsUrl, `?filter={"field_year": "${id}"${filter}}`)
})

export const getRegisrtyObject = ({ objectId }) => request.get({
    url: urlPath(options.registryObjectUrl, `${objectId}/`)
})

export const getGeocodeFeature = ({ layerId, featureId }) => request.get({
    url: urlPath(options.geocodeFeatureUrl, `${layerId}/features/${featureId}`)
})

export const getObjectsRegistryList = ({ pageSize = 10, page = 1, userId }) => request.get({
    url: `/api/v1/mordovia/registry_object${userId ? `?page=${page}&page_size=${pageSize}&filter={"user": ${userId}}` : '/'}`
})

export const syncField = body => request.post({
    url: '/api/v1/mordovia/upload_object_data/import_geohub_layer/',
    body
})

export const getObjectByLayer = ({ page, pageSize, layerId, filterObj = {}, sorterString }) => request.get({
    url: `/api/v1/mordovia/field_info/?page=${page}&page_size=${pageSize}&${filterObj?.year ? `year=${filterObj?.year}&` : ''}${filterObj?.culture ? `culture=${filterObj?.culture}&` : ''}${layerId ? `filter={"registry_object.layer_id": ${layerId}${filterObj?.ownerUser ? `,"owner_user":${filterObj?.ownerUser}` : ''}` : ''}}${sorterString?.length > 0 ? `&sort=["${sorterString}"]` : ''}`
})

export const getSyncedLayers = () => request.get({
    url: `/api/v1/mordovia/upload_object_data/get_layers/`
})

export const updateObjectsPage = body => request.post({
    url: '/api/v1/mordovia/field/update_fields/',
    body
})

export const getLayersByMap = body => request.get({
    url: `/api/v1/mordovia/layer/?filter={"map.map_id_ghb":${body}}`
    // body
})

export const getDistricts = filter => request.get({
    url: `/api/v1/region/district/${filter}`
})

export const getSoils = filter => request.get({
    url: `/api/v1/soil_type/soil_subtype/${filter}`
})
