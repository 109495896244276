import React, { useState, useEffect } from 'react'

import { Spin } from 'antd'
import '../style.less'
import { GEOCODE_MAP_URL_BASE } from '../../../environment'

export default ({
  mapExtent,
  mapId, mapLayer
}) => {
  const [isMapLoading, setIsMapLoading] = useState(true)
  const [isMapVisible, setIsMapVisible] = useState(false)
  const [mapSourceString, setMapSourceString] = useState(null)

  useEffect(() => {
    if (isMapVisible) {
      const iframeLoaded = (loadedFrame) => {
        setIsMapLoading(false)
      }
      const newIframe = document.createElement('iframe')
      newIframe.position = 'relative'
      newIframe.top = 0
      newIframe.left = 0
      newIframe.zIndex = 15000000
      newIframe.marginBottom = '30px'
      newIframe.backgroundColor = "red"
      newIframe.width = "100%"
      newIframe.height = "700px"
      newIframe.id = "mapIframe"
      newIframe.setAttribute("src", mapSourceString)
      // newIframe.setAttribute("SameSite", "None");
      // newIframe.setAttribute("secure", true);
      newIframe.onload = iframeLoaded
      const oldIframe = document.getElementById('mapIframe')
      if (oldIframe) {
        document.getElementById("mapHolder").removeChild(oldIframe)
      }
      document.getElementById("mapHolder").appendChild(newIframe)
    }
  }, [isMapVisible])

  useEffect(() => {
    if (mapSourceString?.length > 0) {
      setIsMapVisible(true)
    }
  }, [mapSourceString])

  useEffect(() => {
    setIsMapLoading(true)

    if (mapId) {
      setIsMapVisible(false)

      if (mapLayer && !mapExtent?.length > 0) {
        setMapSourceString(
          `${GEOCODE_MAP_URL_BASE}/maps/${mapId}?visibleLayers=%7B%22layerId%22:${mapLayer},%22filters%22:%5B%5D%7D`
        )
      } else if (!mapLayer && mapExtent?.length < 1) {
        setMapSourceString(
          `${GEOCODE_MAP_URL_BASE}/maps/${mapId}/`
        )
      } else if (!mapLayer && mapExtent?.length > 0) {
        setMapSourceString(
          `${GEOCODE_MAP_URL_BASE}/maps/${mapId}?%22filters%22:%5B%5D%7D`
        )
      } else if (mapLayer && mapExtent?.length > 0) {
        setMapSourceString(
          `${GEOCODE_MAP_URL_BASE}/maps/${mapId}?extent=[${mapExtent?.join(',')}]&visibleLayers=%7B%22layerId%22:${mapLayer},%22filters%22:%5B%5D%7D`
        )
      }
    }
  }, [
    mapExtent,
    mapId, mapLayer
  ])

  return (
    <div style={{ width: '100%', height: '700px', position: 'relative' }}>
      {isMapLoading && (
        <Spin
          className='map-spinner' />
      )}
      {isMapVisible && (
        <div id="mapHolder" />
      )}
    </div>
  )
}
