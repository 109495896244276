import React, { useState, useEffect, useRef } from 'react'
import {
  Tooltip,
  Button,
  Modal,
  Spin
} from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'

// import ViewerModal from './viewer-modal'
import DeletePopover from './delete-popover'
import request from './../../utils/request'
import './style.less'

const ALLOW_PHOTO_TYPES = [
  'application/pdf',
  'image/tiff',
  'image/jpeg',
  'image/png',
  'image/gif'
]
const ALLOW_DOCUMENT_TYPES = [
  'text/csv',
  'application/msword',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]
const ALLOW_PHOTO_EXTENSIONS = '.pdf, .jpg, .jpeg, .png, .gif, .tiff'
const ALLOW_DOCUMENT_EXTENSIONS = '.csv, .doc, .docx, .odt, .ods, .pdf, .xls, .xlsx'

export default ({
  isBorshevik,
  isPhotoReport,
  cancelHandler,
  confirmHandler,
  activeItem
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isActiveZone, setIsActiveZone] = useState(false)
  const [isOpenedViewer, setIsOpenedViewer] = useState(false)
  const [newFiles, setNewFiles] = useState([])
  const [uploadedFilesGrid, setUploadedFilesGrid] = useState([])
  const [currentFiles, setCurrentFiles] = useState([])
  const [deletedFilesIds, setDeletedFilesIds] = useState([])

  const fileInputRef = useRef(null)

  const API_FILE_CATEGORY = isPhotoReport ? 'photo' : 'document'
  const API_SERVICE_TYPE = isBorshevik ? 'borchsevik' : 'verification_area'
  const API_AREA_FIELD = isBorshevik ? 'scouted_area' : 'verification_area'
  const API_FILE_FIELD = isPhotoReport ? 'image' : 'document'
  const MODAL_TITLE = isPhotoReport ? 'Фотоотчет' : 'Прикрепленные документы'
  const DRAGGABLE_AREA_CONTENT = isPhotoReport ? 'изображения' : 'документа'
  const ALLOW_FILE_EXTENSIONS = isPhotoReport ? ALLOW_PHOTO_EXTENSIONS : ALLOW_DOCUMENT_EXTENSIONS
  const DRAGGABLE_AREA_EXTENSIONS = ALLOW_FILE_EXTENSIONS.replaceAll('.', '')
  const MEGABYTES_SIZE_LIMIT = isPhotoReport ? 20 : 10
  const UPLOAD_SIZE_LIMIT = MEGABYTES_SIZE_LIMIT * 1024 * 1024

  const fillAllowFiles = (files) => {
    const allowedTypes = isPhotoReport ? ALLOW_PHOTO_TYPES : ALLOW_DOCUMENT_TYPES
    files = Array.from(files)
    if (Array.isArray(files)) {
      const result = files.filter(file => allowedTypes.includes(file.type) && file.size <= UPLOAD_SIZE_LIMIT)
      setNewFiles(result)

      const ms = Date.now()
      const newFilesArray = result.map((file, index) => ({
        id: ms + index,
        name: file.name,
      }))

      setCurrentFiles((prevFiles) => {
        const updatedList = [...newFilesArray, ...prevFiles]
        refreshUploadFilesGrid(updatedList)
        return updatedList
      })
    }
  }

  const saveChanges = async () => {
    try {
      setIsLoading(true)
      if (deletedFilesIds.length) {
        await Promise.all(deletedFilesIds.map(fileId => {
          return request.remove({
            url: `/api/v1/${API_SERVICE_TYPE}/${API_FILE_CATEGORY}/${fileId}/`
          })
        }))
        setDeletedFilesIds([])
      }
      if (newFiles.length) {
        await Promise.all(newFiles.map(file => {
          return request.post({
            url: `/api/v1/${API_SERVICE_TYPE}/${API_FILE_CATEGORY}/`,
            body: {
              [API_FILE_FIELD]: file,
              [API_AREA_FIELD]: activeItem
            },
            type: 'formdata'
          })
        }))
      }
    } finally {
      setIsLoading(false)
      confirmHandler()
    }
  }

  const deleteHandler = async (fileId) => {
    if (typeof fileId !== 'number') return

    setCurrentFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter(item => item.id !== fileId)
      refreshUploadFilesGrid(updatedFiles)
      return updatedFiles
    })

    const newIndexForDelete = newFiles.findIndex(item => item.id === fileId)
    if (newIndexForDelete !== -1) {
      const updatedNewFiles = newFiles.slice()
      updatedNewFiles.splice(newIndexForDelete, 1)
      setNewFiles(updatedNewFiles)
    } else {
      setDeletedFilesIds((prevIds) => [...prevIds, fileId])
    }
  }

  const refreshUploadFilesGrid = (files) => {
    const result = []
    files.forEach(item => {
      result.push(
        <div
          className='upload-modal__item'
          key={item.id}
        >
          <PaperClipOutlined className='upload-modal__item-icon' />
          <Tooltip
            placement="bottom"
            title={item.name}
          >
            <div
              className='upload-modal__item-name clickable'
              onClick={() => setIsOpenedViewer(true)}
            >
              {item.name}
            </div>
          </Tooltip>
          <DeletePopover deleteHandler={deleteHandler} item={item} />
        </div>
      )
    })
    setUploadedFilesGrid(result)
  }

  const refreshItems = async () => {
    try {
      if (typeof activeItem !== 'number') return
      setIsLoading(true)
      const response = await request.get({
        url: `/api/v1/${API_SERVICE_TYPE}/${API_FILE_CATEGORY}/`,
        body: {
          filter: JSON.stringify({
            [API_AREA_FIELD]: activeItem
          })
        }
      })
      if (Array.isArray(response?.results)) {
        const preparedResults = response.results.map(item => {
          if (item[API_FILE_FIELD]) {
            const filePathParts = item[API_FILE_FIELD].split('/')
            item.name = decodeURIComponent(filePathParts[filePathParts.length - 1])
          }
          return item
        })
        setCurrentFiles(preparedResults)
        refreshUploadFilesGrid(preparedResults)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    refreshItems()
  }, [])

  const handleFileChange = (event) => {
    try {
      setIsLoading(true)
      fillAllowFiles(event.target.files)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClick = () => {
    fileInputRef.current.click()
  }

  const dragEnterHandler = (event) => {
    event.preventDefault()
    setIsActiveZone(true)
  }

  const dragOverHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const dragLeaveHandler = (event) => {
    event.preventDefault()
    setIsActiveZone(false)
  }

  const dropHandler = (event) => {
    event.preventDefault()
    const files = []
    try {
      setIsLoading(true)
      const dataTransfer = event.dataTransfer
      if (dataTransfer !== null) {
        if (dataTransfer.items && dataTransfer.items.length) {
          Array.from(dataTransfer.items).forEach(item => {
            if (item.kind === 'file') {
              const file = item.getAsFile()
              if (file instanceof File) {
                files.push(file)
              }
            }
          })
        } else {
          Array.from(dataTransfer.files).forEach(file => {
            files.push(file)
          })
        }
      }
    } finally {
      fillAllowFiles(files)
      setIsActiveZone(false)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      visible={true}
      title={MODAL_TITLE}
      onOk={saveChanges}
      onCancel={cancelHandler}
      className='upload-modal'
      footer={[
        <Button key="back" onClick={cancelHandler}>
          Отменить
        </Button>,
        <Button key="submit"
          onClick={saveChanges}
          type="primary"
        >
          Применить
        </Button>
      ]}
    >
      {isLoading && (
        <Spin className='map-spinner' />
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept={ALLOW_FILE_EXTENSIONS}
        multiple
        onChange={handleFileChange}
      />
      <div
        className={`graggable-area${isActiveZone ? ' active' : ''}`}
        onDragEnter={dragEnterHandler}
        onDragLeave={dragLeaveHandler}
        onDragOver={dragOverHandler}
        onDrop={dropHandler}
        onClick={handleClick}
      >
        <img src="/images/upload.svg" className='graggable-area__icon' />
        <div>
          <div className='graggable-area__title'>
            Перетяните файл в эту область
          </div>
          <div className='graggable-area__description'>
            <div>Допустимые форматы: {DRAGGABLE_AREA_EXTENSIONS}</div>
            <div>Размер одного {DRAGGABLE_AREA_CONTENT} не должен превышать {MEGABYTES_SIZE_LIMIT}Мб</div>
          </div>
        </div>
      </div>
      {uploadedFilesGrid.length > 0 && <div className='upload-modal__wrapper'>
        <div className='upload-modal__container'>
          {uploadedFilesGrid}
        </div>
      </div>}
      {/*
      {isOpenedViewer & <ViewerModal
        isPhotoReport={isPhotoReport}
        items={currentFiles}
        closeHandler={setIsOpenedViewer(false)}
      />} */}
    </Modal>
  )
}
