import React, { Component, useState } from 'react'
import {
    Modal, Button, Alert, Checkbox,
    message
} from 'antd'

import request from '../../../utils/request'
import { useTranslation } from 'react-i18next'

function UpdateModalWindow ({ updateId, closeModal, reset }) {
    const { t } = useTranslation('sampling points')

    const [isWaiting, setIsWaiting] = useState(false)
    const [checked, setChecked] = useState(true)

    const updateLayer = async (id) => {
        try {
            setIsWaiting(true)

            const layerInfo = await request.get({
                url: `/api/v1/mordovia/layer/${id}/`
            })

            await request.post({
                url: '/api/v1/mordovia/upload_object_data/import_geohub_layer/',
                body: {
                    map_id: layerInfo?.map.map_id_ghb,
                    layer_id: layerInfo?.layer_id_ghb,
                    object_type: 'points',
                    layer_type: 'agro_points',
                    point_num_column: layerInfo?.geohub_column,
                    polygons_layer_id: checked ? layerInfo?.linked_layers.map(layer => layer.id) : []
                }
            })

            reset()
        } catch (e) {
            message.error(`Status: ${e.status}. ${e.statusText}`)
        } finally {
            setIsWaiting(false)
            closeModal()
        }
    }

    return (
        <Modal
            visible={updateId !== null}
            title={t('update modal.title')}
            footer={[
                <Button
                    disabled={isWaiting}
                    key="back"
                    onClick={() => {
                        closeModal()
                    }}>
                    {t('update modal.cancel button')}
                </Button>,
                <Button
                    loading={isWaiting}
                    key="update"
                    type="primary"
                    onClick={() => updateLayer(updateId)}>
                    {t('update modal.apply button')}
                </Button>
            ]}
            onCancel={() => {
                if (!isWaiting) {
                    closeModal()
                }
            }}>
            <Alert
                style={{ marginBottom: '20px' }}
                message={t('update modal.warning message')}
                type="warning"
                showIcon />
            <div style={{ marginBottom: '20px', fontSize: '16px' }}>
                {t('update modal.line')}
            </div>
            <Checkbox
                checked={checked}
                onChange={(value) =>
                    setChecked(value.target.checked)}>
                {t('update modal.checkbox text')}
            </Checkbox>
        </Modal>
    )
}

export default UpdateModalWindow
