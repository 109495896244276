import * as constants from '../constants'

const initialState = {}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case constants.getObjectByLayer.success: {
            return action.payload
        }

        case constants.getGeocodeFeature.error: {
            return { error: 'Cannot get objects' }
        }

        default:
            return state
    }
}
