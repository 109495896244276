import * as constants from '../constants'
import { addCalculate, clearCalculate, getCalculate } from '../../item-calculate/constants'
import {
    addGroupCalculate,
    clearCalculatorMordovia,
    getCalculatorMordovia,
    setActiveCalculate
} from '../../mordovia/constants'

const initialState = {
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case constants.getNormative.success:
        case constants.addNormative.success:
        case constants.updateNormative.success: {
            return { ...state, ...action.payload }
        }

        case getCalculate.success:
        case addCalculate.success: {
            const { normative } = action.payload
            return normative
        }

        case clearCalculatorMordovia.toString():
        case clearCalculate.toString():
        case constants.clearNormative.toString(): {
            return initialState
        }

        case getCalculatorMordovia.success: {
            const calculator = { ...action.payload?.calculator?.normative }
            return calculator
        }

        case addGroupCalculate.success: {
            const calculator = { ...action.payload?.calculator?.normative }
            return calculator
        }

        case setActiveCalculate.toString(): {
            const calculator = { ...action.payload?.normative }
            return calculator
        }

        default:
            return state
    }
}
