import request from '../../utils/request';

export const getMeteodataRegistry = body => request.post({
    url: `/api/meteo/public/meteo/meteo_telemetry/`,
    body
});

export const getMeteodataStations = body => request.post({
    url: `/api/meteo/public/meteo/`,
    body
});

export const getMeteodataGtk = body => request.post({
    url: `/api/meteo/public/meteo/gtk/`,
    body
});

export const getMeteodataSum = body => request.post({
    url: `/api/meteo/public/meteo/graph_sum/`,
    body
});

export const getMeteodataForecast = body => request.post({
    url: `/api/meteo/public/meteo/forecast/`,
    body
});

export const getMeteodataPest = body => request.post({
    url: `/api/meteo/public/meteo/pest_forecast/`,
    body
});
