import imgPhase1 from './vegimg/phase-1.png';
import imgPhase2 from './vegimg/phase-2.png';
import imgPhase3 from './vegimg/phase-3.png';
import imgPhase4 from './vegimg/phase-4.png';
import imgPhase5 from './vegimg/phase-5.png';
import imgPhase6 from './vegimg/phase-6.png';
import imgPhase7 from './vegimg/phase-7.png';
import imgPhase8 from './vegimg/phase-8.png';
import imgPhase9 from './vegimg/phase-9.png';
import imgPhase10 from './vegimg/phase-10.png';

const plantHerbicide = [
    {
        name: 'Спрут Экстра, ВР',
        activeSubstance: '540г/л глифосата',
        applicationNorm: '2,0-3,0',
        condition: '20-40 см',
        consumption: '100-200'
    },
    {
        name: 'Гранж, ВДГ',
        activeSubstance: '525глифосата+ 105сульфометурон-метил+20хлорсульфурон',
        applicationNorm: '2,0-3,0',
        condition: 'До фазы бутонизация',
        consumption: '100-300'
    },
    {
        name: 'Витара, ВР',
        activeSubstance: '480г/л дикамбы',
        applicationNorm: '1,6-3,1',
        condition: '10-20см',
        consumption: '150-400'
    },
    {
        name: 'Дианат, ВР \n Мономакс, ВР \n Деймос, ВРК \n ЛПХ',
        activeSubstance: '480 г/л дикамбы',
        applicationNorm: '1,6-2,0 весной \n  2,6-3,1 осенью \n 20 мл/3л воды \n 30 мл/3л воды',
        condition: '15 см',
        consumption: '150-400 сенокосы \n 3л/100кв.м газоны и пр.'
    },
    {
        name: 'Генсек, ВГР',
        activeSubstance: '88,5 дикамбы+ 88,5пиклорама+ 177клопиралида г/л',
        applicationNorm: '1,5-2,5',
        condition: 'От фазы розетки до фазы выбрасывания цветоноса',
        consumption: '200-300'
    },
    {
        name: 'Сотейра, ВРК',
        activeSubstance: '33имазамокса+ 15имазапира г/л',
        applicationNorm: '2,0',
        condition: '10-15 см',
        consumption: '300'
    },
    {
        name: 'Грант, ВРК ЛПХ',
        activeSubstance: '33имазамокса+ 15имазапира г/л',
        applicationNorm: '20-40 мл/3л воды',
        condition: '20-30 см',
        consumption: '3л/100 кв.м.'
    },
    {
        name: 'АтронПро, ВДГ',
        activeSubstance: '250имазапира+ 75сульфометурон-метила г/кг',
        applicationNorm: '1,5-3,5',
        condition: 'От 20-30 см (до фазы бутонизации)',
        consumption: '100-300'
    },
    {
        name: 'Магнум, ВДГ ЛПХ',
        activeSubstance: '600 г/кг метсульфурон-метила',
        applicationNorm: '0,05-0,3 \n 2г/3л воды',
        condition: '20-40 см',
        consumption: '100-300 \n 3л/100кв.м.'
    },
    {
        name: 'Зингер, СП',
        activeSubstance: '600 г/кг метсульфурон-метила',
        applicationNorm: '0,15-0,2 \n 0,04-0,05',
        condition: 'До бутонизации',
        consumption: '200-300'
    },
    {
        name: 'Анкор-85, ВДГ',
        activeSubstance: '750 г/кг сульфометурон-метила кислота',
        applicationNorm: '0,12-0,24',
        condition: 'От 20-30 см (до фазы бутонизации)',
        consumption: '100-300'
    },
    {
        name: 'Горгон, ВРК',
        activeSubstance: '350г/л МЦПА+ 150г/л пиклорама',
        applicationNorm: '1,5-3,5',
        condition: 'От фазы розетки до фазы выбрасывания цветоноса',
        consumption: '50-300'
    },
    {
        name: 'Клинч ВДГ',
        activeSubstance: '750г/кг пиклорама',
        applicationNorm: '0,7',
        condition: '20-40 см',
        consumption: '50-300'
    },
];

const mainDescription = {
    1: 'Борщевик - монокарпическое двухлетние или редко многолетние растения.',
    2: 'Ежегодно такие растения вегетируют, накапливают в подземных органах (корне) большой запас веществ, что позволяет им перейти к цветению. После цветения и образования плодов (мерикарпиев), материнская особь погибает.',
    3: 'Стебель ',
    4: 'борщевика Сосновского крупный бороздчато-ребристый, или шероховатый, высота его составляет от одного метра до 3 м (высота некоторых особей может достигать до 5,5 – 6 метров), а толщина у основания стебля достигает 4-6 см (редко – до 18-20 см).',
    5: 'Листья ',
    6: 'тройчато-перисто-рассечённые желтовато-зелёного, зелёного или тёмно-зелёного цвета.',
    7: 'Корневая система ',
    8: 'стержневая, основная масса корней располагается в почве на глубине от 30 до 60 см, и не проникает глубже метра. Соцветие борщевиков сложный зонтик — крупный, от 40 до 80 см в диаметре, состоящий из 70 — 180 лучей. Цветки белые, очень редко розовые до начала распускания; наружные лепестки краевых цветков краевых зонтичков увеличены. Центральный зонтик несёт от 4 000 до 32 000 цветков. Семенная продуктивность главного (центрального) зонтика на один генеративный побег составляет от 8 000 до 64 000 плодов. Зонтики первого порядка имеют от 60 до 100 лучей и несут от 1000 до 4 000 цветков. Число боковых соцветий I и II порядков на одной особи бывает от 3 до 5, редко – до 9 или даже 11 штук. Центральный зонтик уже несёт плоды, зонтики первого порядка цветут, зонтики второго порядка ещё в состоянии бутонизации.',
    9: 'Плод у борщевиков ',
    10: '– колонковый вислоплодник, распадающийся на два мерикарпия. Наличие на плодах выростов, или «крыльев», которые значительно повышают их летучесть, способствуют их активному распространению.',
    11: 'Каждый год образуются',
    12: ' семена ',
    13: 'в большом количестве. В первый год обычно прорастает от 20 до 70 %. На второй год прорастает от 30 до 60 %, из не проросших в первый год семян. Некоторые семена борщевиков, находящиеся в почве, могут порасти лишь через 5-6 или даже 12-15 лет.'
}

const vegetationPhases = [
    {
        id: 1,
        name: 'Всходы',
        year: 'Первый год произрастания',
        info: 'У двудольных растений первым трогается в рост зародышевый корешок. Стебель на первых этапах прорастания петлеобразно изогнут. Этим изгибом он пробивает почву, затем выпрямляется и выносит на поверхность семядоли и почечку. Семядоли зеленеют, некоторое время фотосинтезируют, почка продолжает расти вверх, образуя стебель и первые настоящие листья.',
        image: imgPhase1
    },
    {
        id: 2,
        name: 'Первый настоящий лист',
        year: 'Первый год произрастания',
        info: 'Первый настоящий лист размером с 2 рублевую монету, полностью развернут и макушки розетки видно зачатки второго и последующих листьев, будут крупнее предыдущего листа \n Первый настоящий лист называют ювенильным, так как он резко отличаются от листьев взрослого растения.',
        image: imgPhase2
    },
    {
        id: 3,
        name: 'Формирование розетки',
        year: 'Первый год произрастания',
        info: 'На укороченном стебле листья располагаются близко друг к другу. Междоузлия почти совершенно не развиваются или только едва заметны. Черешки листьев слабо развиты',
        image: imgPhase3
    },
    {
        id: 4,
        name: 'Возобновление вегетации',
        year: 'Второй год произрастания',
        info: 'Появление настоящих листьев после схода снежного покрова.',
        image: imgPhase4
    },
    {
        id: 5,
        name: 'Рост розетки',
        year: 'Второй год произрастания',
        info: 'Появление новых листьев за счёт формирования их из подземного корнестебля.',
        image: imgPhase5
    },
    {
        id: 6,
        name: 'Выход соцветия',
        year: 'Второй год произрастания',
        info: 'Из пазух листьев развиваются цветоносы. Растение образует толстый полый цветонос в диаметре до 15 сантиметров и высотой до 5 метров и более.',
        image: imgPhase6
    },
    {
        id: 7,
        name: 'Бутонизация',
        year: 'Второй год произрастания',
        info: 'Формируется бутон, из которого развивается сложный многолучевой зонтик, в нем насчитывается до 2-3,5 тыс. цветков. На главном стебле образуется до 30 зонтиков. Главный зонтик диаметром 50-80 см, может быть до 1,2 (редко до 1,5) м.',
        image: imgPhase7
    },
    {
        id: 8,
        name: 'Цветение',
        year: 'Второй год произрастания',
        info: 'Зонтики собраны из желто-зеленых, розовых или белых цветков. Общее их количество – от 80000 до 100000 штук. Первым зацветает центральный зонтик; потом боковые зонтики, выходящие из того же узла, что и центральный.',
        image: imgPhase8
    },
    {
        id: 9,
        name: 'Зеленая спелость',
        year: 'Второй год произрастания',
        info: 'Формируются крупные семена с высокой жизнеспособностью.',
        image: imgPhase9
    },
    {
        id: 10,
        name: 'Полная спелость',
        year: 'Второй год произрастания',
        info: 'Плод двусемянка, распадающаяся при созревании на два семени. Строение плодов способствует их распространению при помощи ветра, дождевых и сточных вод, деятельности человека на расстояние до 2 километров. \n Отцветшие борщевики отмирают.',
        image: imgPhase10
    },
]

const methods = {
    agro: {
        1: 'Вспашка',
        2: ' Если возможно проведение вспашки, то её необходимо проводить несколько раз за вегетационный период. Первая вспашка должна быть проведена вскоре после наступления момента выезда в поле. Лучше проводить подрезку корней борщевика, используя плоскорезы. Глубина обработки на горизонте 5-10 см. Важно срезать точку роста борщевиков (им свойственен геотропизм – заглубление точки роста ниже уровня почвы), которую растения затягивают на 3-5 см или даже 7-10 см. Глубина расположения почки зависит от типа почвы, климатических условий региона. В случае отрастания растений от корней после первой вспашки, вторую обработку важно провести до момента разворачивания листьев и вынесения на поверхность соцветий. Для полного уничтожения всех растений борщевика вспашки нужно будет проводить в течение нескольких лет (в зависимости от засоренности полей семенами) - от 2-3 до 5-7 лет.',
        3: 'Осенью вспашки на полях, заросших борщевиками, проводить нельзя. Ибо это будет способствовать накоплению семян в почве, и тогда искоренение борщевиков растянется ещё на несколько лет.',
        4: 'Замещающие посадки',
        5: ' На полях, где проводится уничтожение борщевиков, возможно введение новых видов растений, которые могут быть использованы для восстановления земель сельскохозяйственного назначения. В данном случае могут быть внедрены на поля быстрорастущие и высокопродуктивные злаки (например, костер безостый, ежа сборная) или пропашные (картофель) культуры с соблюдением обычных агротехнических приемов. При этом возможно появление отдельных проростков борщевика через несколько недель после посадки культур. Проростки должны быть выкопаны механическим способом или путем точечного применения гербицидов.',
    },
    mech: {
        1: 'Обрезка',
        2: ' цветков в период бутонизации и начала цветения. Нужно срезать только бутоны, цветки, или мелкие зелёные, не развитые плоды. Так обрезать нужно центральный зонтик и зонтики первого порядка. Зонтики второго и последующих порядков, если они образуются, можно обрезать под основание этого зонтика. Для этого зонтик снизу обхватывают защищённой рукой (в перчатке) и срезают только цветки. Необходимо строго соблюдать правила индивидуальной защиты, чтобы сок борщевика или роса с растворенным в ней соком с растений не попали не только на незащищённые участки тела, но и не промочили одежду.',
        3: 'Скашивание',
        4: ' Это эффективный способ уничтожения борщевиков только при условии, что будет проводиться до цветения борщевика. В течение сезона скашивание надо проводить многократно с интервалом 3-4 недели после предыдущего скашивания. Этот приём нужен для уничтожения всех генеративных побегов борщевика, несущих соцветия, и, соответственно, цветки. Важно не давать борщевикам цвести, чтобы не образовались новые семена. Если скашивать борщевики только один раз в середине цветения – это лишь будет способствовать дальнейшему размножению растений.',
        5: 'Сжигание',
        6: ' Очень эффективный путь уничтожения семян борщевика именно в период их созревания. Тут важно не упустить момент проведения мероприятия. Лучше его проводить до начала полного созревания плодов в центральном, самом крупном зонтике. Этот метод требует максимальной осторожности и аккуратности. Перед поджиганием можно облить растения горючей жидкостью (так, чтобы именно зонтики с плодами были намочены). В период горения зонтиков важно соблюдать все меры предосторожности – ведь из плодов борщевика будут выделяться горючие эфирные масла. Важно соблюдать противопожарную безопасность.',
        7: 'Ручной индивидуальный способ',
        8: ' с использованием лопаты. Применяется ранней весной, как только растения начинают отрастать. Нужно срезать, срубить точку роста борщевика ниже корневой шейки. Если срубить выше, то на корне растения останется несколько спящих почек в листовых пазухах – тогда они пойдут в рост и дадут семена. Заложение и развитие генеративных структур у борщевиков происходит, особенно в северных районах страны, в год вегетации.'
    },
    chem: {
        1: 'Обработка гербицидами',
        2: ' Возможно использование разрешенных к применению на территории РФ гербицидов. Время обработки – с начала отрастания борщевика до начала цветения. Наиболее эффективным применение гербицидов будет в фазе массового отрастания (конец мая - начало июня).',
        3: 'Обработку гербицидами необходимо проводить два раза с перерывом между ними в 30-40 дней.',
        4: 'При обработке борщевика гербицидами нужно следить, чтобы препарат попадал не только на листовую поверхность, но и стекал бы по черешкам в листовую розетку; и достаточно обильно смачивайте гербицидом генеративный побег и соцветия (зонтики, если они будут развиты к моменту обработки)',
        5: 'Перечень гербицидов для борьбы с борщевиком разрешенные к применению в РФ:'
    }
}
export { plantHerbicide, mainDescription, vegetationPhases, methods };