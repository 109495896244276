import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import {
    defaultSaga, getActions
} from '../utils';

import * as constants from './constants';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getMeteodataStations.toString(),
            defaultSaga({
                constants: constants.getMeteodataStations,
                api: api.getMeteodataStations
            })
        ),
        yield takeLatest(
            constants.getMeteodataRegistry.toString(),
            defaultSaga({
                constants: constants.getMeteodataRegistry,
                api: api.getMeteodataRegistry
            })
        ),
        yield takeLatest(
            constants.getMeteodataGtk.toString(),
            defaultSaga({
                constants: constants.getMeteodataGtk,
                api: api.getMeteodataGtk
            })
        ),
        yield takeLatest(
            constants.getMeteodataSum.toString(),
            defaultSaga({
                constants: constants.getMeteodataSum,
                api: api.getMeteodataSum
            })
        ),
        yield takeLatest(
            constants.getMeteodataForecast.toString(),
            defaultSaga({
                constants: constants.getMeteodataForecast,
                api: api.getMeteodataForecast
            })
        ),
        yield takeLatest(
            constants.getMeteodataPest.toString(),
            defaultSaga({
                constants: constants.getMeteodataPest,
                api: api.getMeteodataPest
            })
        ),
    ]);
}