import React from 'react'
import Private from '../../containers/private-route'
import Page from './component'

const Routes = [
  <Private
    exact
    key="verification-area"
    path="/verification-area/:mapId/:layerId?"
    component={Page} />
]

export default Routes
