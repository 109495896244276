import { combineReducers } from 'redux';

import reports from './reports';
import docs from './docs';
import photos from './photos';
import uploadedDoc from './uploaded-doc';
import uploadedPhoto from './uploaded-photo';

export default combineReducers({
    reports,
    docs,
    photos,
    uploadedDoc,
    uploadedPhoto
});
