import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getMeteodataSum.success: {
            return action.payload;
        }


        case constants.getMeteodataSum.try:
        case constants.getMeteodataSum.error: {
            return {};
        }

        default:
            return state;
    }
}