import * as constants from './constants';

export const createViewReport = payload => ({
    type: constants.createViewReport.toString(),
    payload
});

export const deleteViewReport = payload => ({
    type: constants.deleteViewReport.toString(),
    payload
});

export const editViewReport = payload => ({
    type: constants.editViewReport.toString(),
    payload
});

export const getViewReports = payload => ({
    type: constants.getViewReports.toString(),
    payload
});


export const getPhoto = payload => ({
    type: constants.getPhoto.toString(),
    payload
});

export const getAllPhotos = payload => ({
    type: constants.getAllPhotos.toString(),
    payload
});

export const appendPhoto = payload => ({
    type: constants.appendPhoto.toString(),
    payload
});

export const removePhoto = payload => ({
    type: constants.removePhoto.toString(),
    payload
});


export const getDoc = payload => ({
    type: constants.getDoc.toString(),
    payload
});

export const getAllDocs = payload => ({
    type: constants.getAllDocs.toString(),
    payload
});

export const appendDoc = payload => ({
    type: constants.appendDoc.toString(),
    payload
});

export const removeDoc = payload => ({
    type: constants.removeDoc.toString(),
    payload
});
