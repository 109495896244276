import React, { useState, Component, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import {
  DatePicker,
  TreeSelect,
  Dropdown,
  Col,
  Form,
  Row,
  Input,
  Select,
  Button,
  Space,
  Menu
} from 'antd'
import {
  MoreOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import cn from '../../../utils/cn'
import { wordEndingByCount, formatDate } from '../../../helpers'
import { Table, TableFooter, Tooltip } from '../../../components'
import NewDataForm from './new-data-form'

const MAX_RECORDS_IN_PAGINATOR = 40
const DATETIME_PARSE_FORMAT = 'DD.MM.YYYY HH:mm'
const DATETIME_SEND_FORMAT = 'YYYY-MM-DD'
@withRouter
@withTranslation('calculators table')
@cn('calculators-table')
export default class CalculatorsTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedFilterPresenceStatus: null,
      selectedFilterManipulationStatus: null
    }
    this.timeoutId = null
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
      this.updatePaginationInfo(1, null)
    }, 500)
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  onCurrentPageChange = (newPage) => {
    this.updatePaginationInfo(newPage, null)
  };

  updatePaginationInfo = (page, sortingOrder) => {
    let sorterString
    if (sortingOrder) {
      const { order, columnKey } = sortingOrder
      if (order === 'ascend') {
        sorterString = columnKey
      } else if (order === 'descend') {
        sorterString = `-${columnKey}`
      }
    }
    const { updatePagination } = this.props
    updatePagination(page, sorterString)
  };

  get layerColumns () {
    const {
      menuClickHandler = () => { },
      t
    } = this.props
    return [
      {
        title: t('header layer name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (value, row) => {
          return (
            <span className="hoverable-link" onClick={() => this.selectLayer(row?.layer_id_ghb)}>
              {value}
            </span>
          )
        }
      },
      {
        title: t('header polygon num'),
        dataIndex: 'num_of_objects',
        key: 'num_of_objects',
        sorter: true
      },
      {
        title: t('header update date'),
        dataIndex: 'updated',
        key: 'updated',
        sorter: true,
        render: value => value ? formatDate(value) : null
      },
      {
        title: '',
        key: 'actions',
        render: (_, row) => {
          const [isActive, setIsActive] = useState()
          return {
            children: (
              <Dropdown
                overlay={(
                  <Menu onClick={() => setIsActive(false)}>
                    <Menu.Item onClick={() => menuClickHandler('updateLayer', row)}>
                      Обновить
                    </Menu.Item>
                    <Menu.Item onClick={() => menuClickHandler('deleteLayer', row)}>
                      Удалить
                    </Menu.Item>
                  </Menu>
                )}
                trigger={['click']}
                onVisibleChange={(visible) => {
                  setIsActive(visible)
                }}>
                <MoreOutlined style={{ color: isActive ? '#03B575' : '' }} />
              </Dropdown>
            )
          }
        }
      }
    ]
  }

  get reportColumns () {
    const {
      menuClickHandler = () => { },
      statusOptions,
      updateRow,
      t
    } = this.props
    return [
      {
        title: t('header polygon name'),
        dataIndex: 'polygon_name',
        key: 'polygon_name'
      },
      {
        title: t('header obsled date'),
        dataIndex: 'scouted_date',
        key: 'scouted_date',
        width: 180,
        render: (val, row) => {
          return <DatePicker
            defaultValue={val ? moment(val) : null}
            placeholder="Выберите дату"
            format={DATETIME_PARSE_FORMAT}
            onChange={value => updateRow(row, 'scouted_date', value.format(DATETIME_SEND_FORMAT))}
          />
        }
      },
      {
        title: t('header grow fact'),
        dataIndex: 'presence_status',
        key: 'presence_status',
        width: 120,
        render: (val, row) => {
          return <Select
            defaultValue={val}
            allowClear
            options={statusOptions.presence}
            onChange={value => updateRow(row, 'presence_status', value)}
          />
        }
      },
      {
        title: t('header veg phase'),
        dataIndex: 'vegetation_stage',
        key: 'vegetation_stage',
        width: 185,
        render: (val, row) => {
          return row.presence_status === 'present' ? <TreeSelect
            showSearch
            style={{ width: '100%' }}
            defaultValue={val}
            dropdownStyle={{ maxHeight: 400, minWidth: 250, overflow: 'auto' }}
            allowClear
            treeDefaultExpandAll
            treeData={statusOptions.vegetation}
            onChange={value => updateRow(row, 'vegetation_stage', value)}
          /> : null
        }
      },
      {
        title: t('header care fact'),
        dataIndex: 'manipulation_status',
        key: 'manipulation_status',
        width: 120,
        render: (val, row) => {
          return row.presence_status === 'present' ? <Select
            defaultValue={val}
            allowClear
            dropdownStyle={{ minWidth: 120 }}
            options={statusOptions.manipulation}
            onChange={value => updateRow(row, 'manipulation_status', value)}
          /> : null
        }
      },
      {
        title: t('header care date'),
        dataIndex: 'manipulation_date',
        key: 'manipulation_date',
        width: 180,
        render: (val, row) => {
          return row.manipulation_status === 'planned' ? <DatePicker
            defaultValue={val ? moment(val) : null}
            placeholder="Выберите дату"
            format={DATETIME_PARSE_FORMAT}
            onChange={value => updateRow(row, 'manipulation_date', value.format(DATETIME_SEND_FORMAT))}
          /> : null
        }
      },
      {
        title: t('header photo report'),
        dataIndex: 'number_of_photos',
        key: 'number_of_photos',
        render: (val) => {
          const count = Number(val)
          if (count && !Number.isNaN(count)) {
            return (
              <span className="hoverable-link">
                {count} фото
              </span>
            )
          } else {
            return 'Нет фото'
          }
        }
      },
      {
        title: t('header docs'),
        dataIndex: 'number_of_documents',
        key: 'number_of_documents',
        render: (val) => {
          const count = Number(val)
          if (count && !Number.isNaN(count)) {
            return (
              <span className="hoverable-link">
                {count} {wordEndingByCount(count, 'файл', ['', 'a', 'ов'])}
              </span>
            )
          } else {
            return 'Нет документов'
          }
        }
      },
      {
        title: t('header comment'),
        dataIndex: 'comment',
        key: 'comment',
        width: 200,
        render: (val, row) => {
          const inputRef = useRef(null)
          return <Input
            ref={inputRef}
            allowClear
            defaultValue={val}
            autoComplete="off"
            placeholder="Введите текст"
            onBlur={() => updateRow(row, 'comment', inputRef?.current.state.value)}
          />
        }
      },
      {
        key: 'actions',
        fixed: 'right',
        render: (_, row) => {
          const [isActive, setIsActive] = useState()
          return {
            children: (
              <Dropdown
                overlay={(
                  <Menu onClick={() => setIsActive(false)}>
                    <Menu.Item onClick={() => menuClickHandler('attachPhotos', row)}>
                      Прикрепить фотоотчет
                    </Menu.Item>
                    <Menu.Item onClick={() => menuClickHandler('attachDocuments', row)}>
                      Прикрепить документы
                    </Menu.Item>
                    {row.number_of_photos &&
                      <Menu.Item onClick={() => menuClickHandler('downloadPhotos', row)}>
                        Скачать фотоотчет на устройство
                      </Menu.Item>
                    }
                    {row.number_of_documents &&
                      <Menu.Item onClick={() => menuClickHandler('downloadDocuments', row)}>
                        Скачать документы на устройство
                      </Menu.Item>
                    }
                  </Menu>
                )}
                trigger={['click']}
                onVisibleChange={(visible) => {
                  setIsActive(visible)
                }}>
                <MoreOutlined style={{ color: isActive ? '#03B575' : '' }} />
              </Dropdown>
            )
          }
        }
      }
    ]
  }

  get filterItems () {
    const { totalSize, pageSize } = this.props
    const items = []

    for (let i = pageSize; i < totalSize && i <= MAX_RECORDS_IN_PAGINATOR; i += pageSize) {
      items.push({ value: i.toString(), title: i.toString() })
    }

    return items
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    this.updatePaginationInfo(1, sorter)
  }

  selectLayer = (newLayerId) => {
    const {
      openLayer = () => { }
    } = this.props
    if (newLayerId) {
      openLayer(newLayerId)
    }
  }

  selectFilter = (filterCategory, newValue) => {
    switch (filterCategory) {
      case 'presence_status':
        this.setState({ selectedFilterPresenceStatus: newValue })
        break
      case 'manipulation_status':
        this.setState({ selectedFilterManipulationStatus: newValue })
        break
    }
  }

  get visibleRows () {
    const { dataRows } = this.props
    const { selectedFilterPresenceStatus, selectedFilterManipulationStatus } = this.state
    const items = []
    dataRows.forEach(row => {
      if (
        (selectedFilterManipulationStatus && row.manipulation_status !== selectedFilterManipulationStatus) ||
        (selectedFilterPresenceStatus && row.presence_status !== selectedFilterPresenceStatus)
      ) {
        return
      }
      items.push(row)
    })
    return items
  }

  render () {
    const { cn } = this
    const {
      totalSize,
      tableLoading,
      openModal = () => { },
      openedLayer,
      pageSize,
      currentPage,
      statusOptions,
      visibleLayers = [],
      t
    } = this.props

    const layerOptions = Array.isArray(visibleLayers) ? visibleLayers.map(x => ({
      label: x?.name,
      value: x?.layer_id_ghb
    })) : []

    return (!openedLayer && !layerOptions.length) ? (
      <NewDataForm openModal={openModal} />
    ) : (
      <div className={cn()}>
        <Form initialValues={{
          layer: openedLayer,
          presence_status: null,
          manipulation_status: null
        }}>
          <Row gutter={20}>
            <Col span={6} className="field">
              <Space direction="horizontal" style={{ marginBottom: '5px' }}>
                <Tooltip placement="topLeft" title={t('plus button tooltip text')}>
                  <Button onClick={() => openModal(true)} type="primary">+</Button>
                </Tooltip>
                <div>
                  {openedLayer ? 'Слой' : 'Слои полигонов'}
                </div>
              </Space>
              <Form.Item name="layer">
                <Select
                  defaultValue={openedLayer}
                  placeholder={t('select layer placehodler')}
                  onChange={this.selectLayer}
                  showSearch
                  filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                  options={layerOptions}
                />
              </Form.Item>
            </Col>
            {openedLayer && (
              <>
                <Col span={6} className="field">
                  <Form.Item
                    name="presence_status"
                    label={t('grow fact')}
                  >
                    <Select
                      defaultValue={null}
                      placeholder={t('select year placeholder')}
                      allowClear
                      onChange={value => this.selectFilter('presence_status', value)}
                      options={statusOptions.presence}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} className="field">
                  <Form.Item
                    name="manipulation_status"
                    label={t('care fact')}>
                    <Select
                      defaultValue={null}
                      placeholder={t('select year placeholder')}
                      allowClear
                      onChange={value => this.selectFilter('manipulation_status', value)}
                      options={statusOptions.manipulation}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>

        {openedLayer && <div className='borshevik-table__header'>
          Полигоны обследования на наличие борщевика
        </div>}
        <Table
          onChange={this.handleTableChange}
          rowKey="id"
          dataSource={this.visibleRows}
          columns={openedLayer ? [...this.reportColumns] : [...this.layerColumns]}
          pagination={false}
          loading={tableLoading}
        />
        {(openedLayer && this.visibleRows.length > 0) && <TableFooter
          isSized={false}
          filterItems={this.filterItems}
          showSizeChanger={false}
          onChange={this.onCurrentPageChange}
          pageSize={pageSize}
          current={currentPage}
          rowDivider={pageSize.toString()}
          dataSize={totalSize}
        />}
      </div>
    )
  }
}
