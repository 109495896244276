import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Button, Row, Col, Table, Dropdown, Menu, Tooltip
} from 'antd';
import {
    PlusOutlined,
    MoreOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import { Title } from '../../../components';
import DeleteModalWindow from './delete-modal-window';
import UpdateModalWindow from './update-modal-window';

import '../style.less';
import SetupModalWindow from './setup-modal-window';

function PointLayers({
    pointLayers, setModalOpen, reset, layerOptions, mapId
}) {
    const { t } = useTranslation('sampling points');

    const [dataSource, setDataSource] = useState([]);

    const [deleteLayerId, setDeleteLayerId] = useState(null);
    const [updateLayerId, setUpdateLayerId] = useState(null);
    const [setupLayerId, setSetupLayerId] = useState(null);

    useEffect(() => {
        const data = [];
        for (let i = 0; i < pointLayers?.length; i += 1) {
            data.push({
                key: pointLayers[i]?.id,
                name: pointLayers[i]?.name,
                count: pointLayers[i]?.num_of_objects ?? 0,
                layers: pointLayers[i]?.linked_layers.map(layer => layer?.name).join(', '),
                updated: pointLayers[i]?.updated
            });
        }
        setDataSource(data);
    }, [pointLayers]);

    const columns = [
        {
            title: t('layer.layer name col title'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => b.name < a.name,
            render: (data, record) => (
                <Link to={`/sampling-points-layer/${mapId}/${record.key}`}>
                    {data}
                </Link>
            )
        },
        {
            title: t('layer.object amount col title'),
            dataIndex: 'count',
            key: 'count',
            sorter: (a, b) => b.count - a.count
        },
        {
            title: (
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <div>{t('layer.linked layers col title')}</div>
                    <Tooltip
                        title={t('layer.linked layers col title tooltip')}>
                        <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'layers',
            key: 'layers',
            sorter: (a, b) => b.layers.length < a.layers.length,
            render: (data) => (
                <div style={{
                    maxWidth: '360px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                }}>{data}
                </div>
            )
        },
        {
            title: t('layer.updated col title'),
            dataIndex: 'updated',
            key: 'updated',
            sorter: (a, b) => {
                const dateA = new Date(a.updated);
                const dateB = new Date(b.updated);
                return dateB.getTime() < dateA.getTime();
            },
            render: (data) => {
                const date = new Date(data);
                const dateStr = date.toLocaleDateString('ru');
                const timeStr = date.toLocaleTimeString('ru', {
                    hour: '2-digit',
                    minute: '2-digit'
                });
                return (
                    <div>
                        {dateStr} {timeStr}
                    </div>
                );
            }
        },
        {
            dataIndex: 'more',
            key: 'more',
            render: (_, record) => {
                const [isActive, setIsActive] = useState();
                return {
                    children: (
                        <Dropdown
                            overlay={(
                                <Menu onClick={() => setIsActive(false)}>
                                    <Menu.Item onClick={() => setSetupLayerId(record.key)}>
                              {t('layer.link layers option')}
                                    </Menu.Item>
                                    <Menu.Item onClick={() => setUpdateLayerId(record.key)}>
                              {t('layer.update option')}
                                    </Menu.Item>
                                    <Menu.Item onClick={() => setDeleteLayerId(record.key)}>
                              {t('layer.delete option')}
                                    </Menu.Item>
                                </Menu>
                            )}
                            trigger={['click']}
                            onVisibleChange={(visible) => {
                                setIsActive(visible);
                            }}>
                            <MoreOutlined style={{ color: isActive ? '#03B575' : '' }} />
                        </Dropdown>
                    )
                };
            }
        }
    ];

    return (
        <div className="sampling-points__content">
            <Row className="sampling-points__button-row">
                <Col span={18} className="sampling-points__point-layers-row">
                    <Button onClick={() => setModalOpen(true)} type="primary">
                        <PlusOutlined />
                    </Button>
                    <Title
                        style={{ paddingLeft: '0px' }}
                        title={(
                            <div
                                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div style={{ fontSize: '14px', fontWeight: 600 }}>{t('layer.table title')}</div>
                                <Tooltip
                                    title={t('layer.table title tooltip')}>
                                    <QuestionCircleOutlined
                                        style={{ color: 'rgba(0,0,0,0.45)', fontSize: '20px' }} />
                                </Tooltip>
                            </div>
                        )} />
                </Col>
                <Col span={6} />
            </Row>
            <div className="sampling-points__table">
                <div className="sampling-points__divider" />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ pageSize: 10 }} />
            </div>
            <DeleteModalWindow
                deleteId={deleteLayerId}
                closeModal={() => {
                    setDeleteLayerId(null);
                }}
                reset={reset} />
            <UpdateModalWindow
                updateId={updateLayerId}
                closeModal={() => {
                    setUpdateLayerId(null);
                }}
                reset={reset} />
            <SetupModalWindow
                setupId={setupLayerId}
                closeModal={() => {
                    setSetupLayerId(null);
                }}
                layerOptions={layerOptions}
                reset={reset} />
        </div>
    );
}

export default PointLayers;
