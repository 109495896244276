import { getConstant } from '../utils';

export const createViewReport = getConstant('CREATE_VIEW_REPORT');
export const deleteViewReport = getConstant('DELETE_VIEW_REPORT');
export const editViewReport = getConstant('EDIT_VIEW_REPORT');
export const getViewReports = getConstant('GET_VIEW_REPORTS');

export const getPhoto = getConstant('GET_PHOTO_VIEW_REPORT');
export const getAllPhotos = getConstant('GET_ALL_PHOTOS_VIEW_REPORT');
export const appendPhoto = getConstant('APPEND_PHOTO_VIEW_REPORT');
export const removePhoto = getConstant('REMOVE_PHOTO_VIEW_REPORT');

export const getDoc = getConstant('GET_DOC_VIEW_REPORT');
export const getAllDocs = getConstant('GET_ALL_DOCS_VIEW_REPORT');
export const appendDoc = getConstant('APPEND_DOC_VIEW_REPORT');
export const removeDoc = getConstant('REMOVE_DOC_VIEW_REPORT');
