import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects'
import { defaultSaga, getActions } from '../utils'

import * as constants from './constants'
import * as actions from './actions'
import * as api from './api'
import converter from './converter'
import { getAgroEvents } from '../item-events/actions'
import { removeCalculate } from '../item-calculate/api'
import { updateDistribution } from '../item-distribution/api'

function* getPestProbabilityDataGroupSaga ({ payload }) {
    const [start, success, failure] = getActions(
        constants.getPestProbabilityDataGroup.try,
        constants.getPestProbabilityDataGroup.success,
        constants.getPestProbabilityDataGroup.error
    )
    try {
        yield put(start())
        const apiAnswer = yield put(actions.getPestProbabilityData, payload)
        yield console.log('API ANSWER', apiAnswer)
        // yield put(actions.getPestProbabilityDataGroup({ id: payload.vega_key, year: payload.body.year }));
        yield put(success({ calledWith: payload, result: apiAnswer }))
    } catch (error) {
        yield put(failure(error))
    }
}
function* getCalculatorMordoviaSaga ({ payload }) {
    const [start, success, failure] = getActions(
        constants.getCalculatorMordovia.try,
        constants.getCalculatorMordovia.success,
        constants.getCalculatorMordovia.error
    )
    try {
        yield put(start())
        const dataRaw = yield call(api.getCalculatorMordovia, payload)
        const lastYear = Math.max(...dataRaw.year_group?.years.map(x => x.year))
        const data = converter._server2Client(dataRaw, payload.year)
        const yearData = data?.year_group?.years.find(item =>
            item.year?.toString() === payload.year?.toString())
        yield put(actions.getCalculatorsMordoviaShape(`?filter={"vega_key":"${data.vega_key}"}`))
        yield put(actions.getCalculatorsMordoviaForecast({ id: data.vega_key, year: payload.year || lastYear }))
        if (yearData?.id) {
            yield put(actions.getFieldPoints({ id: yearData.id, filter: '' }))
        }
        // yield put(get)
        yield put(getAgroEvents({ vega_key: data.vega_key, year: payload.year || lastYear }))
        yield put(success(data))
    } catch (error) {
        yield put(failure(error))
    }
}

function* updateFieldDistributionSaga ({ payload }) {
    const [start, success, failure] = getActions(
        constants.updateFieldDistribution.try,
        constants.updateFieldDistribution.success,
        constants.updateFieldDistribution.error
    )
    try {
        yield put(start())
        yield call(api.updateFieldDistribution, payload)
        yield put(actions.getCalculatorMordovia({ id: payload.vega_key, year: payload.body.year }))
        yield put(success())
    } catch (error) {
        yield put(failure(error))
    }
}

function* calculatePestProbabilitySaga ({ payload }) {
    const [start, success, failure] = getActions(
        constants.calculatePestProbability.try,
        constants.calculatePestProbability.success,
        constants.calculatePestProbability.error
    )
    try {
        yield put(start())
        const { id } = payload
        const res1 = yield call(api.calculatePestProbability, payload)
        const res2 = yield put(actions.getCalculatorsMordoviaForecast({ id: id, year: payload?.body?.year }))
        yield put(success(res1))
    } catch (error) {
        yield put(failure(error))
    }
}

function* calculatePestProbabilityGroupSaga ({ payload }) {
    const [start, success, failure] = getActions(
        constants.calculatePestProbabilityGroup.try,
        constants.calculatePestProbabilityGroup.success,
        constants.calculatePestProbabilityGroup.error
    )
    try {
        yield put(start())
        const { requestArguments } = payload
        const allAnswers = yield all(
            requestArguments.map(file => put(actions.calculatePestProbability(file)))
        )
        yield console.log('ALL', allAnswers)
        yield put(success(allAnswers))
    } catch (error) {
        yield put(failure(error))
    }
}

export default function* rootSaga () {
    yield all([
        yield takeLatest(
            constants.getCalculatorMordovia.toString(),
            getCalculatorMordoviaSaga
        ),
        yield takeLatest(
            constants.calculatePestProbability.toString(),
            calculatePestProbabilitySaga
        ),
        yield takeLatest(
            constants.calculatePestProbabilityGroup.toString(),
            calculatePestProbabilityGroupSaga
        ),
        yield takeLatest(
            constants.updateFieldDistribution.toString(),
            updateFieldDistributionSaga
        ),
        yield takeLatest(
            constants.getCalculatorsMordovia.toString(),
            defaultSaga({
                constants: constants.getCalculatorsMordovia,
                api: api.getCalculatorsMordovia
            })
        ),
        yield takeLatest(
            constants.getCalculatorsMordoviaShape.toString(),
            defaultSaga({
                constants: constants.getCalculatorsMordoviaShape,
                api: api.getCalculatorsMordoviaShape
            })
        ),
        yield takeLatest(
            constants.getCalculatorsMordoviaForecast.toString(),
            defaultSaga({
                constants: constants.getCalculatorsMordoviaForecast,
                api: api.getCalculatorsMordoviaForecast
            })
        ),
        yield takeLatest(
            constants.updateCalculatorsMordoviaForecast.toString(),
            defaultSaga({
                constants: constants.updateCalculatorsMordoviaForecast,
                api: api.updateCalculatorsMordoviaForecast
            })
        ),
        yield takeLatest(
            constants.getPestProbabilityData.toString(),
            defaultSaga({
                constants: constants.getPestProbabilityData,
                api: api.getPestProbabilityData
            })
        ),
        yield takeLatest(
            constants.addGroupCalculate.toString(),
            defaultSaga({
                constants: constants.addGroupCalculate,
                api: api.addGroupCalculate
            })
        ),
        yield takeLatest(
            constants.removeGroupCalculate.toString(),
            defaultSaga({
                constants: constants.removeGroupCalculate,
                api: removeCalculate
            })
        ),
        yield takeLatest(
            constants.getFieldPoints.toString(),
            defaultSaga({
                constants: constants.getFieldPoints,
                api: api.getFieldPoints
            })
        ),
        yield takeLatest(
            constants.updateDistributionGroupCalculate.toString(),
            defaultSaga({
                constants: constants.updateDistributionGroupCalculate,
                api: updateDistribution
            })
        ),
        yield takeLatest(
            constants.getLayersByMap.toString(),
            defaultSaga({
                constants: constants.getLayersByMap,
                api: api.getLayersByMap
            })
        ),
        yield takeLatest(
            constants.getRegisrtyObject.toString(),
            defaultSaga({
                constants: constants.getRegisrtyObject,
                api: api.getRegisrtyObject
            })
        ),
        yield takeLatest(
            constants.getSoils.toString(),
            defaultSaga({
                constants: constants.getSoils,
                api: api.getSoils
            })
        ),
        yield takeLatest(
            constants.getPestProbabilityDataGroup.toString(),
            getPestProbabilityDataGroupSaga
        ),
        yield takeLatest(
            constants.getDistricts.toString(),
            defaultSaga({
                constants: constants.getDistricts,
                api: api.getDistricts
            })
        ),
        yield takeLatest(
            constants.getGeocodeFeature.toString(),
            defaultSaga({
                constants: constants.getGeocodeFeature,
                api: api.getGeocodeFeature
            })
        ),
        yield takeLatest(
            constants.getObjectsRegistryList.toString(),
            defaultSaga({
                constants: constants.getObjectsRegistryList,
                api: api.getObjectsRegistryList
            })
        ),
        yield takeLatest(
            constants.getObjectByLayer.toString(),
            defaultSaga({
                constants: constants.getObjectByLayer,
                api: api.getObjectByLayer
            })
        ),
        yield takeLatest(
            constants.syncField.toString(),
            defaultSaga({
                constants: constants.syncField,
                api: api.syncField
            })
        ),
        yield takeLatest(
            constants.getSyncedLayers.toString(),
            defaultSaga({
                constants: constants.getSyncedLayers,
                api: api.getSyncedLayers
            })
        ),
        yield takeLatest(
            constants.updateObjectsPage.toString(),
            defaultSaga({
                constants: constants.updateObjectsPage,
                api: api.updateObjectsPage
            })
        )
    ])
}
