import request from '../../utils/request'

export const createViewReport = body => request.post({
    url: `/api/v1/borchsevik/area/`
})

export const deleteViewReport = body => request.remove({
    url: `/api/v1/borchsevik/area/${body.id}/`
})

export const editViewReport = body => request.patch({
    url: `/api/v1/borchsevik/area/8/${body.id}/`
})

export const getViewReports = body => request.get({
    url: '/api/v1/borchsevik/area/',
    body: body
})


export const getPhoto = body => request.post({
    url: `/api/v1/borchsevik/photo/${body.id}/`
})

export const getAllPhotos = body => request.post({
    url: '/api/v1/borchsevik/photo/',
    body: body
})

export const appendPhoto = body => request.post({
    url: `/api/v1/borchsevik/photo/`
})

export const removePhoto = body => request.post({
    url: `/api/v1/borchsevik/photo/${body.id}/`
})


export const getDoc = body => request.post({
    url: `/api/v1/borchsevik/document/${body.id}/`
})

export const getAllDocs = body => request.post({
    url: `/api/v1/borchsevik/document/?scouted_area=${body.id}`
})

export const appendDoc = body => request.post({
    url: `/api/v1/borchsevik/document/`
})

export const removeDoc = body => request.post({
    url: `/api/v1/borchsevik/document/${body.id}/`
})
