import moment from 'moment'
import ConverterClass from '../../utils/converter'
import calculate from '../item-calculate/converter'

class Converter extends ConverterClass {
    _server2Client = (item = {}, year) => {
        const yearData = item?.year_group?.years?.find(i => i.year.toString() === year.toString()) || {}
        return {
            registry_object: item?.registry_object || {},
            year: item?.year_group?.years[0]?.year,
            id: item.id || null,
            culture: item.culture || yearData.culture || {},
            region: item.region || yearData.region || {},
            field_num: item.field_num || yearData.field_num || null,
            google_disk_url: item.google_disk_url || yearData.google_disk_url,
            mechanical_composition: item.mechanical_composition || yearData.mechanical_composition || {},
            degree_of_soil_moisture: item.degree_of_soil_moisture || yearData.degree_of_soil_moisture || {},
            fertilizer_action_year: item.fertilizer_action_year || yearData.fertilizer_action_year || {},
            preceding_culture: item.preceding_culture || yearData.preceding_culture || {},
            created: item.created || yearData.created || null,
            updated: item.updated || yearData.updated || null,
            owner: item.owner_user?.name || yearData.owner || null,
            vega_key: item.vega_key || yearData.vega_key || null,
            area: item.area || yearData.area || null,
            method: item.method || yearData.method || null,
            planned_yield: item.planned_yield || yearData.planned_yield || 0,
            soil_sampling_depth: item.soil_sampling_depth || yearData.soil_sampling_depth || null,
            depth_of_arable_layer: item.depth_of_arable_layer || yearData.depth_of_arable_layer || null,
            ph_salt: item.ph_salt || yearData.ph_salt || null,
            p2o5: item.p2o5 || yearData.p2o5 || null,
            k2o: item.k2o || yearData.k2o || null,
            no3: item.no3 || yearData.no3 || null,
            s: item.s || yearData.s || null,
            organic_substances: item.organic_substances || yearData.organic_substances || null,
            nh4: item.nh4 || yearData.nh4 || null,
            ca: item.ca || yearData.ca || null,
            mg: item.mg || yearData.mg || null,
            na: item.na || yearData.na || null,
            so4: item.so4 || yearData.so4 || null,
            cl: item.cl || yearData.cl || null,
            salt_conductivity: item.salt_conductivity || yearData.salt_conductivity || null,
            ph_water: item.ph_water || yearData.ph_water || null,
            hg: item.hg || yearData.hg || null,
            v: item.v || yearData.v || null,
            b: item.b || yearData.b || null,
            mn: item.mn || yearData.mn || null,
            cu: item.cu || yearData.cu || null,
            mo: item.mo || yearData.mo || null,
            co: item.co || yearData.co || null,
            zn: item.zn || yearData.zn || null,
            pb: item.pb || yearData.pb || null,
            ni: item.ni || yearData.ni || null,
            district: item.district || yearData.district || null,
            oktmo: item.oktmo || yearData.oktmo || null,
            farmland_type: item.farmland_type || yearData.farmland_type || null,
            soil_type: item.soil_type || yearData.soil_type || null,
            agrochemical_calculator: this.agro2Client(item.agrochemical_calculator || yearData.agrochemical_calculator || {}),
            calculator: calculate.toClient(item.calculator || yearData.calculator || {}),
            calculator_group: this.calculatorGroup(item.calculator_group || yearData.calculator_group || {}),
            user: item.user || yearData.user,
            forecast: item.forecast || yearData.forecast || {},
            forecast_error: false,
            year_group: item.year_group || {}
        }
    };

    calculatorGroup = (data = {}) => ({
        id: data.id,
        name: data.name,
        feeding_stages: data.feeding_stages || [],
        calculators: data?.calculators?.map(item => calculate.toClient(item || {}))
    })

    agro2Client = (item = {}) => ({
        id: item.id,
        creation_date: item.creation_date,
        name: item.name || '',
        is_done: item.is_done,
        ph_water: item.ph_water,
        ph_salt: item.ph_salt,
        nh4: item.nh4,
        no3: item.no3,
        p2o5: item.p2o5,
        k2o: item.k2o,
        method: item.method,
        s: item.s,
        ca: item.ca,
        mg: item.mg,
        na: item.na,
        so4: item.so4,
        cl: item.cl,
        organic_substances: item.organic_substances,
        salt_conductivity: item.salt_conductivity,
        ph_water_interval: item.ph_water_interval,
        ph_salt_interval: item.ph_salt_interval,
        nh4_interval: item.nh4_interval,
        no3_interval: item.no3_interval,
        p2o5_interval: item.p2o5_interval,
        k2o_interval: item.k2o_interval,
        s_interval: item.s_interval,
        ca_interval: item.ca_interval,
        mg_interval: item.mg_interval,
        na_interval: item.na_interval,
        so4_interval: item.so4_interval,
        cl_interval: item.cl_interval,
        organic_substances_interval: item.organic_substances_interval,
        salt_conductivity_interval: item.salt_conductivity_interval,
        compare_ph_water_with_ph_salt_interval: item.compare_ph_water_with_ph_salt_interval,
        compare_no3_with_nh4_interval: item.compare_no3_with_nh4_interval,
        user: item.user,
        rb_calculator: this.rb2Client(item.rb_calculator || {}),
        normative_calculator: this.norm2Client(item.normative_calculator || {}),
        type: item.type,
        points: item.points,
        zones: item.zones,
        year: item.year
    })

    list2Client = (items = []) => items.map(this._server2Client)

    calculatorsFullInfo2Client = (rawObject = {}) => {
        const {
            content,
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        } = rawObject

        return {
            content: this.list2Client(content),
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        }
    }

    removedCalculatorId2Client = id => ({ removedCalculatorId: id })

    season2Client = data => ({
        id: data.id || 0,
        fertilizer_type: data.fertilizer_type || {},
        culture: data.culture || {},
        combination: data.combination || '',
        from_autumn: data.from_autumn || '',
        sowing_grain: data.sowing_grain || '',
        first_feeding: data.first_feeding || '',
        second_feeding: data.second_feeding || ''
    })

    rb2Client = rb_calculator => ({
        id: (rb_calculator && rb_calculator.id) || 0,
        name: (rb_calculator && rb_calculator.name) || '',
        user: (rb_calculator && rb_calculator.user) || 0,
        creation_date: (rb_calculator && rb_calculator.creation_date) || '',
        is_done: (rb_calculator && rb_calculator.id_done) || false,
        fertilizer_n: this.fertilizer2Client((rb_calculator && rb_calculator.fertilizer_n) || {}),
        fertilizer_p: this.fertilizer2Client((rb_calculator && rb_calculator.fertilizer_p) || {}),
        fertilizer_k: this.fertilizer2Client((rb_calculator && rb_calculator.fertilizer_k) || {}),
        region: (rb_calculator && rb_calculator.region) || {},
        culture: (rb_calculator && rb_calculator.culture) || {},
        degree_of_soil_moisture: (rb_calculator && rb_calculator.degree_of_soil_moisture) || {},
        analysis_method: (rb_calculator && rb_calculator.analysis_method) || '',
        soil_sampling_depth: (rb_calculator && rb_calculator.soil_sampling_depth) || null,
        fertilizer_action_year: (rb_calculator && rb_calculator.fertilizer_action_year) || {},
        depth_of_arable_layer: (rb_calculator && rb_calculator.depth_of_arable_layer) || {},
        mechanical_composition: (rb_calculator && rb_calculator.mechanical_composition) || {},
        planned_yield: (rb_calculator && rb_calculator.planned_yield) || null,
        agrochemical_calculator: (rb_calculator && rb_calculator.agrochemical_calculator) || 0
    })

    norm2Client = (norm_calculator = {}) => ({
        id: norm_calculator.id || 0,
        name: norm_calculator.name || '',
        user: norm_calculator.user || 0,
        creation_date: norm_calculator.creation_date || '',
        is_done: norm_calculator.id_done || false,
        fertilizer_n: this.fertilizer2Client(norm_calculator.fertilizer_n || {}),
        fertilizer_p: this.fertilizer2Client(norm_calculator.fertilizer_p || {}),
        fertilizer_k: this.fertilizer2Client(norm_calculator.fertilizer_k || {}),
        preceding_culture: norm_calculator.preceding_culture || {},
        preceding_culture_category: norm_calculator.preceding_culture_category || null,
        acidity_group: norm_calculator.acidity_group || {},
        region: norm_calculator.region || {},
        culture: norm_calculator.culture || {},
        degree_of_soil_moisture: norm_calculator.degree_of_soil_moisture || {},
        analysis_method: norm_calculator.analysis_method || '',
        soil_sampling_depth: norm_calculator.soil_sampling_depth || null,
        fertilizer_action_year: norm_calculator.fertilizer_action_year || {},
        depth_of_arable_layer: norm_calculator.depth_of_arable_layer || {},
        mechanical_composition: norm_calculator.mechanical_composition || {},
        planned_yield: norm_calculator.planned_yield || null,
        agrochemical_calculator: norm_calculator.agrochemical_calculator || 0
    })

    fertilizer2Client = (data = {}) => ({
        id: data.id || 0,
        coefficient_conversion_of_nutrients: data.coefficient_conversion_of_nutrients || {},
        coefficient_removal_of_nutrients: data.coefficient_removal_of_nutrients || {},
        coefficient_utilization_factor: data.coefficient_utilization_factor || {},
        coefficient_utilization_nutrients_by_plants: data.coefficient_utilization_nutrients_by_plants || {},
        coefficient_fertilization_per_ton: data.coefficient_fertilization_per_ton || {},
        coefficient_quality_predecessor: data.coefficient_quality_predecessor || {},
        coefficient_soil_acidity: data.coefficient_soil_acidity || {},
        coefficient_mechanical_soil_composition: data.coefficient_mechanical_soil_composition || {},
        season_scheme: this.season2Client(data.season_scheme || {}),
        fertilizer_type: data.fertilizer_type || {},
        report: data.report,
        planned_yield: data.planned_yield,
        nutrients_in_topsoil: data && data.nutrients_in_topsoil,
        rate_of_fertilization: (data && data.rate_of_fertilization) || 0,
        required_volumes: (data && data.required_volumes) || 0,
        from_autumn_percent: data && data.from_autumn_percent,
        sowing_grain_percent: data && data.sowing_grain_percent,
        first_feeding_percent: data && data.first_feeding_percent,
        second_feeding_percent: data && data.second_feeding_percent,
        from_autumn_percent_recommended: data && data.from_autumn_percent_recommended,
        sowing_grain_percent_recommended: data && data.sowing_grain_percent_recommended,
        feeding_percent_recommended: data && data.feeding_percent_recommended,
        from_autumn: data && data.from_autumn,
        sowing_grain: data && data.sowing_grain,
        first_feeding: data && data.first_feeding,
        second_feeding: data && data.second_feeding,
        rb_calculator: data && data.rb_calculator,
        normative_calculator: data && data.normative_calculator,
        fertilizer: data && data.fertilizer
    })
}

export default new Converter()
