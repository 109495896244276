import options from './options'

const { name } = options

export const getCalculatorsMordoviaObject = state => state.models[name].calculators
export const getCalculatorsMordoviaCatalog = state => state.models[name].calculators.results
export const getCalculatorsMordoviaCatalogCount = state => state.models[name].calculators.count
export const getCurrentCalculatorMordovia = state => state.models[name].calculator
export const getCurCalculatorField = state => state.models[name].calculator.calculator
export const getCurrentForecastCalculatorMordovia = state => state.models[name].calculator.forecast
export const getCurrentVegaKey = state => state.models[name].calculator?.vega_key
export const getFieldUser = state => state.models[name].calculator?.user

export const getCurrentForecastErrorCalculatorMordovia = state => state.models[name].calculator.forecast_error
export const isCalculatorMordoviaLoading = state => state.models[name].loading
export const isFieldMordoviaLoading = state => state.models[name].fieldLoading

export const getCurrentShape = state => state.models[name].shape
export const getProbabilityData = state => state.models[name].probabilityData
export const isShapeLoading = state => state.models[name].loading

export const curPoints = state => state.models[name].points.results

export const calculatorsGroupInfo = state => state.models[name].calculator.calculator_group
export const calculatorsGroup = state => state.models[name].calculator.calculator_group?.calculators

export const isForecastLoading = state => state.models[name].forecastLoading
export const currentRegistryObject = state => state.models[name].registryObject
export const currentGeocodeFeature = state => state.models[name].geocodeFeature
export const currentObjectRegistryList = state => state.models[name].registryObjectsList
export const currentSyncedField = state => state.models[name].syncedField
export const currentObjectByLayer = state => state.models[name].objectByLayer.results
export const currentObjectByLayerCount = state => state.models[name].objectByLayer.count
export const currentSyncedLayers = state => state.models[name].syncedLayers
export const currentUpdatedObjects = state => state.models[name].updatedObjects
export const currentUserProbability = state => state.models[name].userProbability
export const currentLayersByMap = state => state.models[name].layersByMap
export const currDistricts = state => state.models[name].districts
export const currSoilTypes = state => state.models[name].soils
export const currNamedPest = state => state.models[name].namedPest
