import moment from 'moment'

export const wordEndingByCount = (count, base, endings) => {
  count = Math.abs(count) % 100
  const count1 = count % 10
  if (count > 10 && count < 20) {
    return base + endings[2]
  } else if (count1 > 1 && count1 < 5) {
    return base + endings[1]
  } else if (count1 === 1) {
    return base + endings[0]
  }
  return base + endings[2]
}

export const formatDate = (date) => {
  return moment(date)?.format('DD.MM.YYYY')
}

export const formatDateTime = (date) => {
  return moment(date)?.format('DD.MM.YYYY HH:MM')
}

export const getLayersList = (group) => {
  if (!group) return []
  const layers = group.layers.slice()
  return group.groups.reduce((arr, item) => {
    return [...arr, ...getLayersList(item)]
  }, layers)
}

export const dbGeometryTypeToFeatureType = (geometryType) => {
  switch (geometryType) {
    case 'POINT':
      return 'Point'
    case 'LINESTRING':
      return 'LineString'
    case 'POLYGON':
      return 'Polygon'
    case 'MULTIPOINT':
      return 'MultiPoint'
    case 'MULTILINESTRING':
      return 'MultiLineString'
    case 'MULTIPOLYGON':
      return 'MultiPolygon'
    default:
      return null
  }
}

export const GEOMETRY_DB_TYPE = 'geometry'

export const getLayerGeometryType = (layer) => {
  let result
  if (layer?.style?.mboxStyle.__geometryType__) {
    return layer.style.mboxStyle.__geometryType__
  }
  const geometryColumn = layer?.columns?.find(column => {
    return column.dbType === GEOMETRY_DB_TYPE
  })

  if (!geometryColumn) {
    return
  }

  const layerTypes = geometryColumn.geometryTypes
  if (Array.isArray(layerTypes) && layerTypes.length) {
    const fType = dbGeometryTypeToFeatureType(layerTypes[0])
    if (fType) {
      result = fType
    }
  }

  return result
}

export const downloadFile = async (responce, fileName) => {
  try {
    const blob = await responce.blob()
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  } finally { }
}
