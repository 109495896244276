import * as constants from '../constants'

const initialState = {
    reports: []
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case constants.getViewReports.success: {
            return action.payload
        }

        default:
            return state
    }
}
