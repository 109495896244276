import { combineReducers } from 'redux';

import user from './user';
import account from './account';
import determinantTypes from './determinant-types';
import determinant from './determinant';
import calculatorsFluid from './calculators-fluid';
import calculatorsFed from './calculators-fed';
import calculatorsMyco from './calculators-myco';
import carouselData from './carousel';
import diseases from './diseases';
import regions from './regions';
import cultures from './cultures';
import fertilizers from './fertilizers';
import pesticides from './pesticides';
import review from './review';
import requests from './requests';
import graph from './graph';
import registrant from './registrant';
import seller from './seller';
import unit_measurement from './unit-of-measurement';
import mordovia from './mordovia';
import dirAcidity from './directory-acidity';
import dirCategoryPreceding from './directory-category-preceding';
import dirPreceding from './directory-preceding';
import dirDegree from './directory-degree-of-soil';
import dirDepth from './directory-depth';
import dirRegionCulture from './directory-region-culture';
import dirSoilComposition from './directory-soil-composition';
import dirSoilDepth from './directory-soil-depth';
import dirSoilType from './directory-soil-type';
import dirYear from './directory-year';
import itemRB from './item-rb';
import itemNormative from './item-normative';
import meteodata from './item-meteodata';
import itemNutrition from './item-nutrition';
import itemCalculate from './item-calculate';
import itemDistribution from './item-distribution';
import itemInterpretation from './item-interpretation';
import itemAgroEvents from './item-events';
import pivotTable from './pivot-table';
import agrotechnicalEvents from './agrotechnical-events';
import leafDiagnostics from './leaf-diagnostics';
import mycologicalRegistry from './mycological-registry';
import sampleLog from './sample-log';
import calculatorsPhyto from './calculators-phyto';
import fedRegistry from './fed-registry';
import generalProtocol from './general-protocol';
import harmfulObjects from './harmful-objects';
import meteodataRegistry from './meteodata-registry';
import borshevik from './borshevik';

const modules = [
    borshevik,
    meteodataRegistry,
    harmfulObjects,
    generalProtocol,
    fedRegistry,
    calculatorsPhyto,
    agrotechnicalEvents,
    dirAcidity,
    dirCategoryPreceding,
    dirPreceding,
    dirDegree,
    dirDepth,
    dirRegionCulture,
    dirSoilComposition,
    dirSoilDepth,
    dirSoilType,
    dirYear,
    meteodata,
    itemCalculate,
    itemDistribution,
    itemInterpretation,
    itemNormative,
    itemNutrition,
    sampleLog,
    itemRB,
    user,
    account,
    determinantTypes,
    determinant,
    diseases,
    regions,
    cultures,
    calculatorsFluid,
    calculatorsFed,
    calculatorsMyco,
    fertilizers,
    pesticides,
    review,
    requests,
    graph,
    registrant,
    seller,
    mordovia,
    unit_measurement,
    pivotTable,
    itemAgroEvents,
    carouselData,
    leafDiagnostics,
    mycologicalRegistry
];

export default modules;

export const actions = modules.reduce(
    (previousValue, { actions }) => ({ ...previousValue, ...actions }), {}
);

export const constants = modules.reduce(
    (previousValue, { constants }) => ({ ...previousValue, ...constants }), {}
);

export const selectors = modules.reduce(
    (previousValue, { selectors }) => ({ ...previousValue, ...selectors }), {}
);

export const reducers = combineReducers(
    modules.reduce(
        (previousValue, { reducers, options }) => (reducers
            ? ({ ...previousValue, [options.name]: reducers }) : previousValue), {}
    )
);

export const saga = modules.reduce(
    (previousValue, { saga }) => (saga ? [...previousValue, saga] : previousValue), []
);
