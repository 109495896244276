import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import {
  Button,
  Modal,
  Row,
  message,
  notification,
  Breadcrumb,
  Tooltip,
  Select,
  Form,
  Layout,
  Tabs
} from 'antd'
import { QuestionCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import 'ol/ol.css'

import Map from './components/map'
import { PanelLayout, UploadModal } from '../../components'
import { getLayersList, getLayerGeometryType, downloadFile } from '../../helpers'
import CalculatorsTable from './components/calculators-table'
import './style.less'
import { useDispatch, useSelector } from 'react-redux'
import { getLayersByMap, syncField } from '../../models/mordovia/actions'
import {
  currentGeocodeFeature,
  currentLayersByMap,
  currentSyncedField
} from '../../models/mordovia/selectors'
import { GEOCODE_MAP_URL_BASE } from '../../environment'
import GeneralInfo from './components/general-info'
const { PanelHeader, PanelContent } = PanelLayout
import request from '../../utils/request'

const { TabPane } = Tabs

const DEFAULT_PAGE_SIZE = 10
const LAYER_TYPE = 'borschevik'

const FieldRegistry = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation('field registry')
  const { t: t2 } = useTranslation('borshevik')

  const fieldShape = useSelector(state => currentGeocodeFeature(state))
  const syncResult = useSelector(state => currentSyncedField(state))
  const layersByMap = useSelector(state => currentLayersByMap(state))

  const [isMounted, setIsMounted] = useState(true)
  const [mapExtent, setMapExtent] = useState([])
  const [openedMapId, setOpenedMapId] = useState()
  const [openedGeohubLayerId, setOpenedGeohubLayerId] = useState()
  const [openedAgrohubLayerId, setOpenedAgrohubLayerId] = useState()
  const [activeLayerId, setActiveLayerId] = useState()
  const [activeReportId, setActiveReportId] = useState()
  const [selectLayerOptions, setSelectLayerOptions] = useState([])
  const [columnOptions, setColumnOptions] = useState([])
  const [visibleLayers, setVisibleLayers] = useState([])
  const [visibleReports, setVisibleReports] = useState([])
  const [openedLayerName, setOpenedLayerName] = useState()
  const [openedMapName, setOpenedMapName] = useState()
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [addModalForm] = Form.useForm()
  const [currentTab, setCurrentTab] = useState("1")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalSize, setTotalSize] = useState(0)
  const [selectedAddModalColumn, setSelectedAddModalColumn] = useState()
  const [selectedAddModalLayer, setSelectedAddModalLayer] = useState()
  const [tableLoading, setTableLoading] = useState(false)
  const [statusOptions, setStatusOptions] = useState({
    manipulation: [],
    vegetation: [],
    presence: []
  })

  const [isOpenedAddModal, setIsOpenedAddModal] = useState(false)
  const [isOpenedUpdateModal, setIsOpenedUpdateModal] = useState(false)
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState(false)
  const [isOpenedAttachPhotosModal, setIsOpenedAttachPhotosModal] = useState(false)
  const [isOpenedAttachDocsModal, setIsOpenedAttachDocsModal] = useState(false)

  const updateLayerInfo = (geohubLayerId) => {
    if (Array.isArray(layersByMap)) {
      setVisibleLayers(layersByMap.filter(x => {
        return x?.layer_type === LAYER_TYPE && x?.is_deleted !== true
      }))
      const layerObject = layersByMap.find(x => x?.layer_id_ghb === geohubLayerId)
      if (layerObject) {
        setOpenedLayerName(layerObject.name)
        setOpenedAgrohubLayerId(layerObject.id)
        updateBorshevikLayerReports(layerObject.id, 1)
      }
    }
  }

  useEffect(() => {
    updateLayerInfo(openedGeohubLayerId)
  }, [layersByMap])

  const updateMapInfo = async (mapId) => {
    try {
      const res = await request.get({
        url: `/api/v1/mordovia/map/`,
        body: {
          filter: JSON.stringify({
            map_id_ghb: mapId
          })
        }
      })
      const maps = res.results
      if (maps?.length && isMounted) {
        setOpenedMapName(maps[0].name)
      }
    } finally {
    }
  }

  useEffect(() => {
    const mapId = Number(match?.params?.mapId)
    if (match?.params?.mapId && !Number.isNaN(mapId)) {
      setOpenedMapId(mapId)
      localStorage.setItem('teleagronom-last-opened-map', mapId)
      dispatch(getLayersByMap(mapId))
      updateMapInfo(mapId)
      getGeohubMapTree(mapId)
    } else {
      setOpenedMapName()
      setOpenedMapId()
    }
  }, [match.params.mapId])

  const updateBorshevikLayerReports = async (agrohubLayerId, page, sorterString) => {
    if (!agrohubLayerId) return
    try {
      setTableLoading(true)
      const response = await request.get({
        url: '/api/v1/borchsevik/area/',
        body: {
          page: page,
          page_size: DEFAULT_PAGE_SIZE,
          sort: sorterString,
          filter: JSON.stringify({
            'polygon.layer': agrohubLayerId
          })
        }
      })
      if (isMounted) {
        if (typeof response.count === 'number') {
          setTotalSize(response.count)
        }
        if (Array.isArray(response?.results)) {
          setVisibleReports(response.results)
        }
      }
    } finally {
      if (isMounted) {
        setTableLoading(false)
      }
    }
  }

  useEffect(() => {
    setOpenedAgrohubLayerId()
    setVisibleReports([])
    setCurrentPage(1)
    const layerId = Number(match?.params?.layerId)
    if (match?.params?.layerId && !Number.isNaN(layerId)) {
      setTotalSize(0)
      setOpenedGeohubLayerId(layerId)
      updateLayerInfo(layerId)
      getLayerInfo(layerId)
    } else {
      setTotalSize(visibleLayers.length)
      setOpenedLayerName()
      setOpenedGeohubLayerId()
    }
  }, [match.params.layerId])

  useEffect(() => {
    const result = [{
      id: 'toMaps',
      label: t('my maps'),
      link: '/'
    }]
    if (openedMapName) {
      result.push({
        id: 'toRegistry',
        label: openedMapName,
        link: `/field-registry/${openedMapId}/`
      })
      result.push({
        id: 'toBorshevik',
        label: t('borshevik'),
        link: `/borshevik/${openedMapId}/`
      })
      if (openedLayerName) {
        result.push({
          id: 'toBorshevikLayer',
          label: openedLayerName,
          link: `/borshevik/${openedMapId}/${openedGeohubLayerId}`
        })
      }
    }
    setBreadcrumbs(result.map(item => {
      return (
        <Breadcrumb.Item key={item.id} style={{ fontSize: '24px' }}>
          <Link to={item.link}>{item.label}</Link >
        </Breadcrumb.Item>
      )
    }))
  }, [openedLayerName, openedMapName])

  useEffect(() => {
    if (fieldShape?.error?.length > 0) {
      message.error('Ошибка при получении объекта с geohub')
    }
  }, [fieldShape])

  const getGeohubMapTree = async (mapId) => {
    try {
      setTableLoading(true)
      const response = await request.get({
        url: `${GEOCODE_MAP_URL_BASE}/api/maps/${mapId}`
      })
      if (response.groups) {
        const mapLayers = getLayersList(response.groups)
        const polygonLayers = mapLayers.filter(layer => {
          const layerType = getLayerGeometryType(layer)
          return ['MultiPolygon', 'Polygon'].includes(layerType)
        })
        if (Array.isArray(polygonLayers)) {
          setSelectLayerOptions(polygonLayers.map(x => ({
            label: x?.name,
            value: x?.id
          })))
        }
      }
    } finally {
      setTableLoading(false)
    }
  }

  const clearAllAddFormFields = () => {
    addModalForm?.setFieldsValue({
      layer: null,
      type: null,
      column: null
    })
    setSelectedAddModalLayer(null)
    setSelectedAddModalColumn(null)
    setColumnOptions([])
    setIsOpenedAddModal(false)
  }

  useEffect(() => {
    if (syncResult?.isResult && syncResult?.results?.length > 0) {
      notification.success({
        message: t('sync success ok title'),
        description: t('sync success ok text')
      })

      const resultingArray = []
      if (Array.isArray(syncResult?.results)) {
        syncResult?.results?.slice(0, 9)?.forEach(reportObj => {
          resultingArray.push(reportObj)
        })
      }
      setIsOpenedAddModal(false)
    } else if (!syncResult?.isResult && syncResult?.error === 'backend error') {
      notification.error({
        message: t('Error'),
        description: t('Backend error')
      })

      clearAllAddFormFields()
    }
  }, [syncResult])

  const delayedMapLayersUpdate = () => {
    const newT = setTimeout(() => {
      dispatch(getLayersByMap(openedMapId))
      clearTimeout(newT)
    }, 5000)
  }

  const handleOKAddModal = () => {
    addModalForm?.submit()

    if (openedMapId && activeLayerId && selectedAddModalColumn) {
      dispatch(syncField({
        map_id: openedMapId,
        layer_id: activeLayerId,
        object_type: 'polygons',
        layer_type: LAYER_TYPE,
        field_num_column: selectedAddModalColumn
      }))
      delayedMapLayersUpdate()
    }
    clearAllAddFormFields()
  }

  const handleOKUpdateModal = () => {
    const layerObject = layersByMap.find(x => x?.layer_id_ghb === activeLayerId)
    if (openedMapId && layerObject && activeLayerId) {
      dispatch(syncField({
        map_id: openedMapId,
        layer_id: activeLayerId,
        object_type: 'polygons',
        layer_type: LAYER_TYPE,
        field_num_column: layerObject.geohub_column
      }))
      delayedMapLayersUpdate()
    }
    setIsOpenedUpdateModal(false)
  }

  const handleOKDeleteModal = async () => {
    const layerObject = layersByMap.find(x => x?.layer_id_ghb === activeLayerId)
    if (layerObject) {
      await request.patch({
        url: `/api/v1/mordovia/layer/${layerObject.id}/`,
        body: {
          is_deleted: true
        }
      })
      await dispatch(getLayersByMap(openedMapId))
    }
    setIsOpenedDeleteModal(false)
  }

  const getLayerInfo = async (layerId, onlyColumns) => {
    try {
      const response = await request.get({
        url: `${GEOCODE_MAP_URL_BASE}/api/layers/${layerId}`
      })
      if (!onlyColumns && response?.extent) {
        let extentArray = []
        response.extent?.xMin > 0 && extentArray.push(response.extent?.xMin)
        response.extent?.yMin > 0 && extentArray.push(response.extent?.yMin)
        response.extent?.xMax > 0 && extentArray.push(response.extent?.xMax)
        response.extent?.yMax > 0 && extentArray.push(response.extent?.yMax)
        if (extentArray.length > 0) {
          setMapExtent(extentArray)
        }
      }
      if (Array.isArray(response?.columns)) {
        setColumnOptions(response.columns.map(dataCol => ({
          label: dataCol?.name,
          value: dataCol?.name
        })))
      }
    } finally {

    }
  }

  useEffect(() => {
    if (selectedAddModalLayer) {
      getLayerInfo(selectedAddModalLayer, true)
    }
  }, [selectedAddModalLayer])

  const openLayer = (layerId) => {
    if (openedMapId && layerId) {
      history.push(`/borshevik/${openedMapId}/${layerId}`)
    }
  }

  const goBack = () => {
    if (openedGeohubLayerId) {
      history.push(`/borshevik/${openedMapId}`)
    } else {
      history.push('/')
    }
  }

  const downloadPhotos = async (row) => {
    try {
      const responce = await fetch(`/api/v1/borchsevik/area/${row.id}/download_photos/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      downloadFile(responce, row.name + '_photos.zip')
    } finally {
    }
  }

  const downloadDocuments = async (row) => {
    try {
      const responce = await fetch(`/api/v1/borchsevik/area/${row.id}/download_documents/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      downloadFile(responce, row.name + '_documents.zip')
    } finally {
    }
  }

  const menuClickHandler = (category, row) => {
    if (!category && !row) return
    if (openedGeohubLayerId) {
      setActiveReportId(row.id)
    } else {
      setActiveLayerId(row.layer_id_ghb)
    }
    switch (category) {
      case 'updateLayer':
        setIsOpenedUpdateModal(true)
        break
      case 'deleteLayer':
        setIsOpenedDeleteModal(true)
        break
      case 'attachPhotos':
        setIsOpenedAttachPhotosModal(true)
        break
      case 'attachDocuments':
        setIsOpenedAttachDocsModal(true)
        break
      case 'downloadPhotos':
        downloadPhotos(row)
        break
      case 'downloadDocuments':
        downloadDocuments(row)
        break
    }
  }

  const updatePagination = (page, sorterString) => {
    setCurrentPage(page)
    updateBorshevikLayerReports(openedAgrohubLayerId, page, sorterString)
  }

  const selectFilterOptionsHandler = (input, option) => {
    return (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())
  }

  const closeUploadModal = () => {
    setIsOpenedAttachDocsModal(false)
    setIsOpenedAttachPhotosModal(false)
  }

  const uploadCancelHandler = () => {
    closeUploadModal()
  }

  const uploadConfirmHandler = () => {
    closeUploadModal()
  }

  const parseResponceOptions = (container, responce, property, isTree) => {
    if (responce && responce.hasOwnProperty(property)) {
      for (const childProp in responce[property]) {
        const propValue = responce[property][childProp]
        container.push({
          [isTree ? 'title' : 'label']: childProp,
          value: propValue
        })
      }
    }
    return container
  }

  const getManipulationStatusesOptions = async () => {
    const result = []
    try {
      const responce = await request.get({
        url: '/api/v1/borchsevik/options/manipulation_statuses/'
      })
      parseResponceOptions(result, responce, 'statuses')
    } finally {
      return result
    }
  }

  const getPresenceStatusesOptions = async () => {
    const result = []
    try {
      const responce = await request.get({
        url: '/api/v1/borchsevik/options/presence_statuses/'
      })
      parseResponceOptions(result, responce, 'statuses')
    } finally {
      return result
    }
  }

  const getVegetationStatusesOptions = async () => {
    const result = [{
      value: 'first_year',
      title: 'Первый год произрастания',
      children: []
    }, {
      value: 'second_year',
      title: 'Второй год произрастания',
      children: []
    }]
    try {
      const responce = await request.get({
        url: '/api/v1/borchsevik/options/vegetation_stages/'
      })
      parseResponceOptions(result[0].children, responce, 'first_year', true)
      parseResponceOptions(result[1].children, responce, 'second_year', true)
    } finally {
      return result
    }
  }

  const setBorchsevikOptions = async () => {
    try {
      const [
        manipulationStatuses,
        vegetationStatuses,
        presenceStatuses
      ] = await Promise.all([
        getManipulationStatusesOptions(),
        getVegetationStatusesOptions(),
        getPresenceStatusesOptions()
      ])
      if (isMounted) {
        setStatusOptions({
          manipulation: manipulationStatuses,
          vegetation: vegetationStatuses,
          presence: presenceStatuses
        })
      }
    } finally {
    }
  }

  useEffect(() => {
    setIsMounted(true)
    setBorchsevikOptions()
    return () => {
      setIsMounted(false)
    }
  }, [])

  const updateRow = useCallback(async (row, property, value) => {
    if (
      (row[property] === value) ||
      (typeof value !== 'string' && typeof value !== 'number')
    ) {
      return
    }
    try {
      const responce = await request.patch({
        url: `/api/v1/borchsevik/area/${row.id}/`,
        body: {
          [property]: value
        }
      })
      const updatedReports = visibleReports.map(item => {
        if (item.id === row.id) {
          return { ...item, ...responce }
        }
        return item
      })
      setVisibleReports(updatedReports)
    } finally {
    }
  })

  return (
    <Layout key="page-field-registry">
      <PanelLayout>
        <PanelHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px'
            }}>
            <Tooltip
              placement="bottom"
              title="Вернуться на страницу “Карты“"
            >
              <Button onClick={goBack}>
                <ArrowLeftOutlined />
              </Button>
            </Tooltip>
            <Breadcrumb>
              {breadcrumbs}
            </Breadcrumb>
          </div>
        </PanelHeader>
        <PanelContent>
          <Tabs
            onChange={setCurrentTab}
            activeKey={currentTab}
          >
            <TabPane
              tab={<span>Полигоны обследования</span>}
              key="1"
            >
              <Map
                mapExtent={mapExtent}
                mapId={openedMapId}
                mapLayer={openedGeohubLayerId}
                style={{ width: '100%' }}
              />
              <Row style={{ height: '30px' }} />
              <CalculatorsTable
                menuClickHandler={menuClickHandler}
                openModal={setIsOpenedAddModal}
                openedLayer={openedGeohubLayerId}
                pageSize={DEFAULT_PAGE_SIZE}
                currentPage={currentPage}
                totalSize={totalSize}
                dataRows={openedGeohubLayerId ? visibleReports : visibleLayers}
                visibleLayers={visibleLayers}
                statusOptions={statusOptions}
                updatePagination={updatePagination}
                openLayer={openLayer}
                updateRow={updateRow}
                tableLoading={tableLoading}
                className="map-table"
              />
            </TabPane>
            <TabPane
              tab={<span>Справочная информация</span>}
              key="2"
            >
              <GeneralInfo />
            </TabPane>
          </Tabs>

          <Modal
            visible={isOpenedAddModal}
            title={(
              <div style={{ display: 'flex', gap: '5px' }}>
                <div>Подготовка полигонов к работе</div>
                <Tooltip title="Полигоны выбранного слоя станут доступны для внесения информации о них">
                  <QuestionCircleOutlined
                    style={{
                      display: 'grid',
                      placeItems: 'center',
                      color: 'rgba(0,0,0,0.45)'
                    }}
                  />
                </Tooltip>
              </div>
            )}
            onOk={handleOKAddModal}
            onCancel={clearAllAddFormFields}
            footer={[
              <Button key="back" onClick={clearAllAddFormFields}>
                {t('cancel button')}
              </Button>,
              <Button key="submit" type="primary" onClick={handleOKAddModal}>
                {t('ok button')}
              </Button>
            ]}
          >
            <Form
              labelCol={{ span: 24 }}
              form={addModalForm}
            >
              <Form.Item
                name={"layer"}
                label="Укажите наименование слоя"
                rules={[{ required: true, message: t('please fill in') }]}
                tooltip="В списке отображаются только слои с объектами типа “полигон”"
              >
                <Select
                  showSearch
                  filterOption={selectFilterOptionsHandler}
                  placeholder="Выберите слой"
                  value={selectedAddModalLayer}
                  options={selectLayerOptions}
                  onChange={(newValue) => {
                    setSelectedAddModalColumn(null)
                    setActiveLayerId(newValue)
                    setSelectedAddModalLayer(newValue)
                    addModalForm.setFieldsValue({ layer: newValue })
                  }}
                />
              </Form.Item>

              <Form.Item
                name={"column"}
                label={t2('column modal header')}
                rules={[{ required: true, message: t('please fill in') }]}
                tooltip="Необходимо указать наименование столбца из таблицы kml-файла, загруженного на карту"
              >
                <Select
                  showSearch
                  filterOption={selectFilterOptionsHandler}
                  placeholder="Выберите столбец"
                  value={selectedAddModalColumn}
                  style={{ width: '100%' }}
                  options={columnOptions}
                  onChange={setSelectedAddModalColumn}
                />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            visible={isOpenedUpdateModal}
            title={t2('update modal title')}
            onOk={handleOKUpdateModal}
            onCancel={() => setIsOpenedUpdateModal(false)}
            footer={[
              <Button key="back" onClick={() => setIsOpenedUpdateModal(false)}>
                {t('cancel button')}
              </Button>,
              <Button key="submit"
                onClick={handleOKUpdateModal}
                type="primary">
                {t('ok button')}
              </Button>
            ]}
          >
            <div className="warning-red">
              <span style={{ color: 'red' }}>!</span>{t2('warning red update text')}
            </div>
            <div style={{ marginTop: '15px' }} className="maintext">
              {t2('update text')}
            </div>
          </Modal>

          <Modal
            visible={isOpenedDeleteModal}
            title={t2('delete modal title')}
            onOk={handleOKDeleteModal}
            onCancel={() => setIsOpenedDeleteModal(false)}
            footer={[
              <Button key="back" onClick={() => setIsOpenedDeleteModal(false)}>
                {t('cancel button')}
              </Button>,
              <Button key="submit"
                onClick={handleOKDeleteModal}
                danger type="primary" >
                {t2('delete button')}
              </Button>
            ]}
          >
            <div className="maintext">
              {t2('delete text one')}
            </div>
            <div style={{ marginBottom: '25px' }} className="maintext">
              {t2('delete text two')}
            </div>
            <div className="warning-red">
              <span style={{ color: 'red' }}>!</span>{t2('warning red delete text')}
            </div>
          </Modal>

          {(isOpenedAttachDocsModal || isOpenedAttachPhotosModal) && <UploadModal
            isBorshevik={true}
            activeItem={activeReportId}
            isPhotoReport={isOpenedAttachPhotosModal}
            cancelHandler={uploadCancelHandler}
            confirmHandler={uploadConfirmHandler}
          />}
        </PanelContent>
      </PanelLayout>
    </Layout>
  )
}

export default FieldRegistry
