import React from 'react'
import Private from '../../containers/private-route'
import Page from './component'

const Routes = [
  <Private
    exact
    key="borshevik"
    path="/borshevik/:mapId/:layerId?"
    component={Page} />
]

export default Routes
